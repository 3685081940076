import { getDataFromDB } from 'app/indexedDB'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DictionaryItem } from 'types/IDictionary'

interface IDictSelect {
  dictType: EDictionaryNS
  onChange?: (_?: DictionaryItem) => void
  /** Окраска для выбираемого справочника **/
  stainId?: number
}

const useDictSelect = <T extends TDict>({ dictType, onChange, stainId }: IDictSelect) => {
  const isDictLoading = useTypedSelector((state) => state.reports.isDictionaryLoading)
  const dictionary = useLiveQuery(() => getDataFromDB(dictType)) as T
  const { data: dictionaryData, updatedAt } = { ...dictionary }
  const [searchValue, setSearch] = useState('')
  const { t } = useTranslation()
  const placeholder = t('Поиск по названию или коду')

  // Приводим полученный dictionary к типу Array
  const resolvedData = useMemo(() => {
    // При отсутствии окраски, справочник не изменяется
    if (stainId === undefined) return dictionaryData
    // При пустой окраске справочник недоступен
    if (stainId === null && Array.isArray(dictionaryData)) return []
    // При валидной окраске и производим поиск по справочнику
    if (typeof dictionaryData === 'object' && !Array.isArray(dictionaryData)) {
      return dictionaryData[stainId] || null
    }
    return dictionaryData
  }, [dictionaryData, stainId])
  const clearSearch = () => setSearch('')

  const onSelect = useCallback(
    (id: string) => {
      if (!resolvedData) return

      const dict = resolvedData.find((item) => item.shortName === id)

      if (onChange && dict) onChange(dict)
    },
    [updatedAt],
  )

  const createSelectedValue = (dictItem?: DictionaryItem) =>
    dictItem ? `${dictItem.shortName}: ${dictItem.name}` : undefined

  return {
    clearSearch,
    createSelectedValue,
    dictionaryData: resolvedData,
    isDictLoading,
    onSelect,
    placeholder,
    searchValue,
    setSearch,
    updatedAt,
  }
}

export default useDictSelect
