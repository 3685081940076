import { Form } from 'antd'
import { useTypedDispatch } from 'app/redux/lib/selector'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import { useMergedStructuredDescriptions } from 'features/reports/hooks/useMergedStructuredDescriptions'
import { useFormValidators } from 'features/reports/hooks/useValidations'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import ReportStructuredDescriptionForm from 'features/reports/ui/report-creation/right/ReportStructuredDescriptionForm'
import { Inner, MedicalReportFormItem, Outer, StyledTextArea } from 'features/reports/ui/styled'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { debounce } from 'lodash'
import { viewerPageSlice } from 'pages/viewer'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import ICase from 'types/ICase'

/**
 * Длина строки ввода в TextArea
 */
const TEXT_AREA_LINE_HEIGHT = 20
/**
 * Максимальная высота для автоматического изменения. 8 строк, одна строка 20px
 */
const MAX_TEXTAREA_AUTO_SIZE = 168
/**
 * Длина метрики пикселей в стилях
 */
const STYLE_PIXEL_LENGTH = 2
/**
 * Буффер для обработки ресайза поля TextArea
 */
const RESIZE_DELAY = 300
/**
 * Расстояние между элементами формы
 */
const INNER_GAP = 8

export type TextAreaSize = {
  /** Ширина поля */
  width: number
  /** Высота поля */
  height: number
}

interface Props {
  /** ID кейса */
  caseId: number
}

const ReportCreationFormRight: FC<Props> = ({ caseId }) => {
  const dispatch = useTypedDispatch()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const [formInnerHeight, setFormInnerHeight] = useState<number>(0)
  const innerFormRef = useRef<HTMLDivElement>(null)
  const { form, initialReport, isSigning, onValuesChange } = useReportCreationContext()
  const { customInfo, structuredMicroDescription } = initialReport
  const toggleInputFocus = (payload: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(payload))

  const wsId = Number(useCurrentWorkspaceId())
  const queryClient = useQueryClient()
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const { fieldSet, structuredMacroDescription } = caseRecord || {}
  const isIHC = fieldSet === 'IHC'
  const isDZM_IHC = isDZM && isIHC
  const isDZM_NotIHC = isDZM && !isIHC
  const isGemotestOrDZM_NotIHC = isGemotest || isDZM_NotIHC
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: wsId,
  })

  const { t } = useTranslation()

  /** Мемомизированное значение суммы вертикальных паддингов формы  */
  const formInnerPaddingHeight = useMemo(() => {
    const inner = innerFormRef.current
    const innerStyle = inner ? window.getComputedStyle(inner) : null

    return formInnerHeight && innerStyle
      ? Number(innerStyle.getPropertyValue('padding-top').slice(0, -STYLE_PIXEL_LENGTH)) +
          Number(innerStyle.getPropertyValue('padding-bottom').slice(0, -STYLE_PIXEL_LENGTH))
      : 0
  }, [formInnerHeight])

  /** Мемомизированое значение суммы вертикальных марджинов элементов формы */
  const formItemsMarginHeight = useMemo(() => {
    const inner = innerFormRef.current
    const items = inner?.children

    let marginHeight = 0
    if (formInnerHeight && inner && items?.length) {
      for (let i = 0; i < items.length; i++) {
        const itemStyle = inner ? window.getComputedStyle(items[i]) : null

        if (itemStyle) {
          marginHeight +=
            Number(itemStyle.getPropertyValue('margin-top').slice(0, -STYLE_PIXEL_LENGTH)) +
            Number(itemStyle.getPropertyValue('margin-bottom').slice(0, -STYLE_PIXEL_LENGTH))
        }
      }
    }

    return marginHeight
  }, [formInnerHeight])

  /** Обработчик обновления максимальной высоты для каждого элемента формы TextArea */
  const updateTextAreaMaxHeight = () => {
    const inner = innerFormRef.current
    const innerHeight = Number(inner?.clientHeight) - formInnerPaddingHeight
    const items = inner?.children

    let childrenHeight = formItemsMarginHeight
    if (innerHeight > 0 && items?.length) {
      for (let i = 0; i < items.length; i++) {
        childrenHeight += items[i]?.clientHeight
      }

      /*
        Считаем сумму расстояний между элементами формы
       */
      const totalGapHeight = (items.length - 1) * INNER_GAP
      const freeHeight = innerHeight - childrenHeight - totalGapHeight

      for (let i = 0; i < items.length; i++) {
        const textArea = items[i]?.getElementsByTagName('textarea')[0]

        if (textArea) {
          if (freeHeight >= 0) {
            const maxHeight = textArea.clientHeight + freeHeight
            textArea.style.height = `${textArea.clientHeight}px`
            textArea.style.maxHeight = `${maxHeight}px`
          } else {
            const negativeHeight = freeHeight / items.length
            textArea.style.height = `${textArea.clientHeight + negativeHeight}px`
            textArea.style.maxHeight = `${textArea.clientHeight + negativeHeight}px`
          }
        }
      }
    }
  }

  const debouncedUpdateTextAreaMaxHeight = debounce(updateTextAreaMaxHeight, RESIZE_DELAY)

  const getTextAreaMaxHeight = (fieldName: string) => {
    const target = document.getElementById(fieldName)
    return target ? Number(target.style.maxHeight.slice(0, -STYLE_PIXEL_LENGTH)) : 0
  }

  /** Обработчкик события ресайза TextArea */
  const resizeTextArea = (size: TextAreaSize, fieldName: string) => {
    const maxHeight = getTextAreaMaxHeight(fieldName)
    const height = size.height
    localStorage.setItem(`reportForm.${fieldName}.height`, String(height > maxHeight ? maxHeight : height))
    updateTextAreaMaxHeight()
  }

  /** Дебаунс обработчкика ресайза TextArea */
  const debouncedResizeTextArea = debounce(resizeTextArea, RESIZE_DELAY)

  /** Метод получения сохраненной высоты TextArea */
  const getTextAreaLocalStorageHeight = (fieldName: string) => {
    const height = localStorage.getItem(`reportForm.${fieldName}.height`)

    return height ? Number(height) : undefined
  }

  /** Обработчкик события изменения текста TextArea */
  const onChangeTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    const scrollHeight = target.scrollHeight
    const maxHeight = getTextAreaMaxHeight(target.id)

    // ничего не делаем, когда уже достигли максимальной высоты или поле больше 8 строк
    if (height === maxHeight || height > MAX_TEXTAREA_AUTO_SIZE) {
      return
    }

    // увеличиваем поле, когда есть свободное пространтсво
    if (scrollHeight > height) {
      let autoHeight = scrollHeight >= MAX_TEXTAREA_AUTO_SIZE ? MAX_TEXTAREA_AUTO_SIZE : scrollHeight

      if (autoHeight >= maxHeight) {
        autoHeight = maxHeight
      }

      target.style.height = `${autoHeight}px`
    }
    updateTextAreaMaxHeight()
  }

  /** TODO возможно убрать
   * Обработчик события клика на Enter TextArea */
  const onPressEnterTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    if (height < MAX_TEXTAREA_AUTO_SIZE) {
      target.style.height = `${height + TEXT_AREA_LINE_HEIGHT}px`
    }
    updateTextAreaMaxHeight()
  }

  useEffect(() => {
    setFormInnerHeight(Number(innerFormRef?.current?.clientHeight))
    debouncedUpdateTextAreaMaxHeight()
  }, [innerFormRef?.current?.clientHeight])

  useEffect(() => {
    toggleInputFocus(true)
    return () => {
      toggleInputFocus(false)
    }
  }, [])

  const mergedStructuredMacroDescription = useMergedStructuredDescriptions(
    caseBiomaterial,
    structuredMacroDescription ?? [],
  )
  const mergedStructuredMicroDescription = useMergedStructuredDescriptions(
    caseBiomaterial,
    structuredMicroDescription ?? [],
  )

  const {
    validateMessages,
    validators: { stringValidator },
  } = useFormValidators(isSigning)

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ maxWidth: '100%', width: '100%' }}
      validateMessages={validateMessages}
      onValuesChange={onValuesChange}
    >
      <Outer>
        <Inner ref={innerFormRef} style={{ gap: INNER_GAP }}>
          {/* Макро- и микроописание для гемотеста*/}
          {isGemotest && (
            <>
              <MedicalReportFormItem label={t('Макроописание')} name="caseMacroDescription">
                <StyledTextArea
                  style={{ height: getTextAreaLocalStorageHeight('caseMacroDescription') }}
                  onResize={(size) => debouncedResizeTextArea(size, 'caseMacroDescription')}
                  onChange={onChangeTextArea}
                  onPressEnter={onPressEnterTextArea}
                />
              </MedicalReportFormItem>
              <MedicalReportFormItem label={t('Микроописание')} name="microDescription">
                <StyledTextArea
                  style={{ height: getTextAreaLocalStorageHeight('microDescription') }}
                  onResize={(size) => debouncedResizeTextArea(size, 'microDescription')}
                  onChange={onChangeTextArea}
                  onPressEnter={onPressEnterTextArea}
                />
              </MedicalReportFormItem>
            </>
          )}
          {/* Структурированное Макро- и микроописание для ДЗМ*/}
          {isDZM && (
            <>
              <ReportStructuredDescriptionForm
                data={mergedStructuredMacroDescription ?? null}
                name="structuredMacroDescription"
                label={t('Макроописание')}
              />
              <ReportStructuredDescriptionForm
                data={mergedStructuredMicroDescription ?? null}
                name="structuredMicroDescription"
                label={t(isIHC ? 'Микроописание (ИГХ)' : 'Микроописание')}
              />
            </>
          )}

          {/* Результат ИГХ исследования */}
          {(isGemotest || isDZM_IHC) && (
            <MedicalReportFormItem
              label={t('Результат ИГХ исследования')}
              name="ihcResult"
              rules={isDZM_IHC ? [{ required: true, validator: stringValidator }] : []}
            >
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('ihcResult') }}
                onResize={(size) => debouncedResizeTextArea(size, 'ihcResult')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
                defaultValue={customInfo?.ihcResult || ''}
              />
            </MedicalReportFormItem>
          )}

          {/* Результаты доп. исследований */}
          {isDZM_IHC && (
            <MedicalReportFormItem label={t('Результаты доп. исследований')} name="additionalResearchResult">
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('additionalResearchResult') }}
                onResize={(size) => debouncedResizeTextArea(size, 'additionalResearchResult')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
                defaultValue={customInfo?.additionalResearchResult || ''}
              />
            </MedicalReportFormItem>
          )}

          {/* Патологоанатомическое заключение */}
          {isGemotestOrDZM_NotIHC && (
            <MedicalReportFormItem
              label={t(isGemotest ? 'Заключение' : 'Патологоанатомическое заключение')}
              name="report"
              rules={isDZM ? [{ required: true, validator: stringValidator }] : [{ required: true }]}
            >
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('report') }}
                onResize={(size) => debouncedResizeTextArea(size, 'report')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </MedicalReportFormItem>
          )}

          {/* Доп. замечания и рекомендации */}
          {isGemotestOrDZM_NotIHC && (
            <MedicalReportFormItem label={t('Доп. замечания и рекомендации')} name="comment">
              <StyledTextArea
                style={{ height: getTextAreaLocalStorageHeight('comment') }}
                onResize={(size) => debouncedResizeTextArea(size, 'comment')}
                onChange={onChangeTextArea}
                onPressEnter={onPressEnterTextArea}
              />
            </MedicalReportFormItem>
          )}
        </Inner>
      </Outer>
    </Form>
  )
}

export default ReportCreationFormRight
