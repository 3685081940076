import { useTypedSelector } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import { ONE_MINUTE_IN_MS, SEC_TO_MINUTES } from 'features/cases-management/ui/cases-table/CasesTable'
import { reportsSlice } from 'features/reports'
import { onSaveReportError, useReportEditMutation } from 'features/reports/api/query'
import { Icd10Detailed } from 'features/reports/ui/ReportInfo'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisLockSignedMedicalReportAfterSec, useLisMode } from 'features/workspace/model/workspacesSlice'
import { selectAtlasViewerUrlSlideId } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateForDisplay } from 'shared/lib/date'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { TextElement, TitleElement } from 'shared/ui/kit'
import { StyledButtonElement } from 'shared/ui/kit/ui/ButtonElement'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import { ICaseDTO, ICaseRelation } from 'types/ICase'
import IReport from 'types/IReport'
import IUserRole from 'types/IUserRole'

/** Ширина правой части таба */
const LEFT_CONTENT_WIDTH = 272

type TProps = {
  /** Данные о заключение */
  report?: IReport
  caseRecord?: ICaseDTO
  /** ref от модалки создания заключения */
  reportCreationModalRef: React.MutableRefObject<ModalHandle | null>
  /** ID кейса */
  caseId: number
}

const ContentWrapper = styled.div`
  display: grid;
  row-gap: 8px;
  width: 100%;
  padding: 16px;
`

const RowWrapper = styled.div`
  display: grid;
  row-gap: 2px;
  width: 100%;
`

const StyledHorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-border-1);
`

const RowDescription = ({ description, title }: { title: string; description: string }) => (
  <RowWrapper>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </RowWrapper>
)

const StyledVerticalDivider = styled.div<{ left: number }>`
  position: absolute;
  border: 1px solid var(--color-border-1);
  left: ${({ left }) => `${left}px`};
  height: 100%;
`
/** Opacity для заблокированной кнопки */
const DISABLED_BUTTON_OPACITY = 0.4

const ReportTabContent: FC<TProps> = ({ caseId, caseRecord, report, reportCreationModalRef }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    caseMacroDescription,
    comment,
    consultedBy,
    createdAt,
    createdBy,
    customInfo,
    icd10,
    icd10DetailedList,
    locked,
    medicalReportId,
    microDescription,
    morphologyCode,
    report: textReport,
    signed,
    signedAt,
    structuredMacroDescription,
    structuredMicroDescription,
  } = report || {}

  const lisMode = useLisMode()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const wsId = Number(useCurrentWorkspaceId())
  const { fieldSet, relation } = caseRecord || {}
  const isRestricted = relation === ICaseRelation.RESTRICTED
  const currentUser = useTypedSelector((state) => state.user.user)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isRoleDoctor = authorities?.includes(IUserRole.ROLE_DOCTOR)
  const isAtlas = !useSelector(selectAtlasViewerUrlSlideId)
  const { activeViewerId: viewerId } = useOpenViewers()
  const { isLoading, mutate: editMutate } = useReportEditMutation()
  const isAtlasSource = useTypedSelector((state) => state.viewers[viewerId].viewer.source) === 'ATLAS'
  // период в секундах, в рамках которого можно отменить подписание
  const signCancelPeriod = useLisLockSignedMedicalReportAfterSec() || 0
  // текущее время
  const currentDate = new Date()
  // количество прошедших минут после подписания
  const passedMinutes = signedAt
    ? Math.ceil((currentDate.getTime() - new Date(signedAt || '').getTime()) / ONE_MINUTE_IN_MS)
    : 0
  const leftMinutes = Number(signCancelPeriod) / SEC_TO_MINUTES - passedMinutes
  const [signCancelMinutes, setSignCancelMinutes] = useState<number>(leftMinutes > 0 ? leftMinutes : 0)
  const isEditable =
    ((isGemotest || isDZM) && !report?.signed) ||
    (lisMode === 'none' && report?.createdBy?.userId === currentUser?.userId)
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: wsId,
  })
  const isDetailedList = checkIntegration('report', 'icd10DetailedList', lisMode) && icd10DetailedList
  // запускаем таймер для обновления оставшегося времени отмены подписания в ЛИС
  useEffect(() => {
    const timerId = !locked
      ? setInterval(() => {
          signCancelMinutes && setSignCancelMinutes(signCancelMinutes - 1)
        }, ONE_MINUTE_IN_MS)
      : null

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [locked, signCancelMinutes])

  // обновляем время отмены подписания, если поменлся его признак
  useEffect(() => {
    if (signed && signedAt && leftMinutes > 0 && (isGemotest || isDZM)) {
      setSignCancelMinutes(leftMinutes)
    }
  }, [signed, signedAt, leftMinutes, isGemotest, isDZM])

  const editReport = () => {
    reportCreationModalRef.current?.open()
    dispatch(reportsSlice.actions.setInitialReport(report))
  }

  const copyReport = () => {
    reportCreationModalRef.current?.open()
    dispatch(
      reportsSlice.actions.setInitialReport({
        ...report,
        medicalReportId: undefined,
      }),
    )
  }

  const ReportTabRightContent = () => (
    <div style={{ width: '100%' }}>
      <ContentWrapper>
        <div style={{ maxWidth: 820 }}>
          {isDZM && (
            <>
              {structuredMacroDescription?.some((item) => item.text) && (
                <RowDescription
                  title={t('Макроописание')}
                  description={structuredMacroDescription?.map((item) => item.text).join(' ')}
                />
              )}
              {structuredMicroDescription?.some((item) => item.text) && (
                <RowDescription
                  title={t(fieldSet === 'IHC' ? 'Микроописание (ИГХ)' : 'Микроописание')}
                  description={structuredMicroDescription?.map((item) => item.text).join(' ')}
                />
              )}
            </>
          )}
          {!isDZM && (
            <>
              {caseMacroDescription && <RowDescription title={t('Макроописание')} description={caseMacroDescription} />}
              {microDescription && <RowDescription title={t('Микроописание')} description={microDescription} />}
            </>
          )}
          {checkIntegration('report', 'report', lisMode, lisMode === 'DZM' ? fieldSet === 'DEFAULT' : true) &&
            textReport && (
              <RowDescription
                title={t(isDZM ? 'Патологоанатомическое заключение' : 'Заключение')}
                description={textReport}
              />
            )}
          {checkIntegration('report', 'comment', lisMode, lisMode === 'DZM' ? fieldSet === 'DEFAULT' : true) &&
            comment && <RowDescription title={getFieldLabelBylisMode('comment', lisMode)} description={comment} />}
        </div>
      </ContentWrapper>
    </div>
  )

  /** Запрос на отмену подписания */
  const onCancelSign = async () => {
    caseId &&
      editMutate(
        {
          caseId,
          // принудительно отменяем подписание
          report: { ...report, signed: false },
          reportId: Number(medicalReportId),
        },
        { onError: (e) => onSaveReportError(e, caseId) },
      )
  }
  const ReportTabLeftContent = () => (
    <div>
      <ContentWrapper
        style={{
          padding: '16px',
          width: `${LEFT_CONTENT_WIDTH}px`,
        }}
      >
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy && (
          <RowDescription
            description={createdAt ? formatDateForDisplay(createdAt) : '-'}
            title={t('Дата заключения')}
          />
        )}
        {icd10 && !isDetailedList && (
          <RowDescription title={t('МКБ-10')} description={`${icd10.shortName} ${icd10.name}`} />
        )}
        {morphologyCode && !isDetailedList && (
          <RowDescription
            title={t('Морфологический код (МКБ-О)')}
            description={`${morphologyCode.shortName} ${morphologyCode.name}`}
          />
        )}
        {checkIntegration('report', 'complexity', lisMode) && customInfo?.complexity && (
          <RowDescription description={String(customInfo?.complexity)} title={t('Категория сложности')} />
        )}
        {checkIntegration('report', 'additionalResearchResult', lisMode) && customInfo?.additionalResearchResult && (
          <RowDescription description={customInfo?.additionalResearchResult} title={t('Результат доп исследований')} />
        )}
        {checkIntegration('report', 'ihcResult', lisMode, fieldSet === 'IHC') && customInfo?.ihcResult && (
          <RowDescription title={getFieldLabelBylisMode('ihcResult', lisMode)} description={customInfo?.ihcResult} />
        )}

        {isDetailedList &&
          icd10DetailedList.map((it, idx) => <Icd10Detailed key={idx} {...it} showTnn={fieldSet === 'DEFAULT'} />)}

        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy?.userId && (
          <RowDescription title={t('Врач-патологоанатом')} description={createdBy?.fullname} />
        )}

        {checkIntegration('report', 'consultedBy', lisMode) && consultedBy?.userId && (
          <RowDescription title={t('Консультант')} description={consultedBy?.fullname} />
        )}
        {(isEditable || ((isGemotest || isDZM) && !!signed) || (isAtlas && !isAtlasSource && lisMode === 'none')) &&
          !isRestricted && <StyledHorizontalDivider />}

        {isEditable && !isRestricted && isRoleDoctor && (
          <StyledButtonElement
            disabled={isDZM && !caseBiomaterial?.length}
            onClick={editReport}
            style={{
              opacity: isDZM && !caseBiomaterial?.length ? DISABLED_BUTTON_OPACITY : undefined,
            }}
          >
            {t('Редактировать')}
          </StyledButtonElement>
        )}

        {isAtlas && !isRestricted && isRoleDoctor && !isAtlasSource && lisMode === 'none' && (
          <StyledButtonElement onClick={copyReport}>{t('Дублировать')}</StyledButtonElement>
        )}

        {(isGemotest || isDZM) && !!signed && !locked && !!signCancelMinutes && !isRestricted && (
          <div>
            <StyledButtonElement
              style={{ width: `100%` }}
              disabled={isRestricted}
              onClick={onCancelSign}
              loading={isLoading}
            >
              {t('Отменить подписание')}
            </StyledButtonElement>
            <div style={{ marginTop: '8px', textAlign: 'center' }}>
              <TextElement>{`${t('Отмена подписания будет еще доступна в течение')} ${signCancelMinutes} ${t('минут')}${
                signCancelMinutes === 1 ? t('ы') : ''
              }`}</TextElement>
            </div>
          </div>
        )}
      </ContentWrapper>
    </div>
  )

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <ReportTabLeftContent />
      <StyledVerticalDivider left={LEFT_CONTENT_WIDTH} />
      <ReportTabRightContent />
    </div>
  )
}

export default ReportTabContent

const StyledDescription = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
`

const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-text-3);
  }
`
