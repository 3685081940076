import { EDictionaryNS } from 'features/dictionaries/api/service'
import i18n from 'i18next'
import React, { FC, PropsWithChildren, useMemo } from 'react'
import { getLocalizedItem, localizeDictionary } from 'shared/lib/common'
import useDictSelect from 'shared/lib/dict-select/useDictSelect'
import { createOption } from 'shared/lib/dict-select/utils'
import { InputElement, SelectElement, SelectElementProps } from 'shared/ui/kit'
import styled from 'styled-components'
import { DictionaryItem } from 'types/IDictionary'

import { LoadingContainer, StyledSpineElement } from './DictLoading.styled'
import SelectedDict from './SelectedDict'

const StyledSelect = styled(SelectElement)`
  &.tnm-select {
    .ant-select-selector {
      padding: 4px 0 4px 4px !important;
      overflow: hidden;

      .ant-select-selection-search {
        width: 100%;
        left: 0 !important;
        padding-left: 4px;
      }
    }
  }
`

interface Props {
  dictType: EDictionaryNS
  value?: DictionaryItem
  onChange?: (_?: DictionaryItem) => void
  localized?: boolean
  multiline?: boolean
  selectProps?: SelectElementProps
  /** Окраска для выбираемого справочника **/
  stainId?: number
}

const DictSelect: FC<PropsWithChildren<Props>> = ({
  dictType,
  localized = false,
  multiline = false,
  onChange,
  selectProps,
  stainId,
  value,
}) => {
  const {
    clearSearch,
    createSelectedValue,
    dictionaryData,
    isDictLoading,
    onSelect,
    placeholder,
    searchValue,
    setSearch,
  } = useDictSelect({ dictType, onChange, stainId })

  // Окраска будет невалидна только для блока "Результаты по маркерам"
  const isInvalidStain = (stainId && !dictionaryData) || stainId === null
  let dictData: DictionaryItem[] = dictionaryData
  let selectedValue = value

  dictData = useMemo(() => {
    if (!localized) return dictData
    if (!dictionaryData) return []
    return localizeDictionary(dictionaryData, dictType)
  }, [dictionaryData, i18n.language])

  if (selectedValue && localized) {
    selectedValue = value && getLocalizedItem(value, dictType)
  }

  // При isInvalidStain селектор меняется на Input
  if (isInvalidStain) {
    return (
      <InputElement
        onChange={(e) =>
          onChange?.({
            name: e.target.value,
            shortName: e.target.value,
          } as DictionaryItem)
        }
      />
    )
  }
  if (selectedValue && multiline) {
    return <SelectedDict dict={selectedValue} onDelete={() => onChange?.()} key={selectedValue.shortName} />
  }

  return (
    <StyledSelect
      placeholder={placeholder}
      onSearch={setSearch}
      searchValue={searchValue}
      showSearch
      filterOption={false}
      onSelect={clearSearch}
      value={createSelectedValue(selectedValue)}
      onChange={onSelect}
      {...selectProps}
    >
      {isDictLoading ? (
        <LoadingContainer>
          <StyledSpineElement />
        </LoadingContainer>
      ) : (
        dictData?.map((dict) => createOption(dict, searchValue))
      )}
    </StyledSelect>
  )
}

export default DictSelect
