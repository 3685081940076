import { TDict, TDictICD10 } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import i18n from 'i18next'
import React, { FC, useCallback, useMemo } from 'react'
import { getLocalizedItem, groupLabelLocalized, localizeIcd10Dictionary } from 'shared/lib/common'
import useDictSelect from 'shared/lib/dict-select/useDictSelect'
import { createOptionGroup } from 'shared/lib/dict-select/utils'
import { LoadingContainer, StyledSpineElement } from 'shared/ui/dict-select/DictLoading.styled'
import SelectedDict from 'shared/ui/dict-select/SelectedDict'
import { SelectElement } from 'shared/ui/kit'
import { DictionaryItem } from 'types/IDictionary'

interface Props {
  value?: DictionaryItem
  onChange?: (icd10?: DictionaryItem) => void
  filterCb?: (icd10: DictionaryItem) => boolean
  multiline: boolean
}

const Icd10SelectElement: FC<Props> = ({ filterCb, multiline, onChange, value }) => {
  const {
    clearSearch,
    createSelectedValue,
    dictionaryData,
    isDictLoading,
    placeholder,
    searchValue,
    setSearch,
    updatedAt,
  } = useDictSelect({
    dictType: EDictionaryNS.ICD_10,
  })

  const findIcd10 = (group2Icd10Codes: Record<string, DictionaryItem[]>, id: string) =>
    Object.values(group2Icd10Codes)
      .flatMap((it) => it)
      .find((icd10) => icd10.shortName === id)

  const onIcd10Change = useCallback(
    (id: string) => {
      if (isICD10Data(dictionaryData)) {
        const icd10 = findIcd10(dictionaryData.group2Icd10Codes, id)

        icd10 && onChange?.(icd10)
      }
    },
    [updatedAt],
  )

  const isICD10Data = (data: TDictICD10['data'] | TDict['data']): data is TDictICD10['data'] =>
    data && 'group2Icd10Codes' in data

  const icd10Localized = value && getLocalizedItem(value, EDictionaryNS.ICD_10)

  const dictionaryLocalized: Record<string, DictionaryItem[]> = useMemo(() => {
    if (!isICD10Data(dictionaryData)) return {}
    return localizeIcd10Dictionary(dictionaryData.group2Icd10Codes, EDictionaryNS.ICD_10)
  }, [dictionaryData, i18n.language])

  const renderIcd10 = useMemo(
    () =>
      Object.entries(dictionaryLocalized)?.map((entries) => {
        if (!isICD10Data(dictionaryData)) return []
        const { icd10s, keyGroup } = groupLabelLocalized(...entries, EDictionaryNS.ICD_10)
        return createOptionGroup(keyGroup, icd10s, searchValue, filterCb)
      }),
    [dictionaryData, i18n.language],
  )

  if (multiline && icd10Localized) {
    return <SelectedDict dict={icd10Localized} onDelete={() => onChange?.()} key={icd10Localized.shortName} />
  }

  return (
    <SelectElement
      onChange={onIcd10Change}
      onSelect={clearSearch}
      showSearch
      filterOption={false}
      onSearch={setSearch}
      placeholder={placeholder}
      value={createSelectedValue(value)}
    >
      {isDictLoading ? (
        <LoadingContainer>
          <StyledSpineElement />
        </LoadingContainer>
      ) : (
        renderIcd10
      )}
    </SelectElement>
  )
}

export default Icd10SelectElement
