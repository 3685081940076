import i18next from 'i18next'

/** Код ошибки для специальной ошибки на UI*/
export const CONFLICT_SERVER_CODE = 409
/** Код ошибки для конфликта из-за десинхронизации медицинского отчёта */
export const CONFLICT_VERSION_CODE = 'MEDICAL_REPORT_DESYNC'
/** Код ошибки для конфликта, возникающего при подписании медицинского отчёта */
export const CONFLICT_SIGNED_CODE = 'Conflict'

const t = i18next.t

/**  Получить сообщение об ошибке для UI */
export const getUiErrorMessage = (errorCode?: string, isConflict?: boolean, updatedUser?: string) => {
  if (errorCode === CONFLICT_SIGNED_CODE) {
    return `${t('За время редактирования заключение было подписано пользователем')} ${updatedUser}`
  }

  if (errorCode === CONFLICT_VERSION_CODE) {
    return `${t('За время редактирования содержимое черновика было изменено пользователем')} ${updatedUser}`
  }

  if (isConflict) {
    return t('Заключение было подписано в ЛИС, редактирование невозможно')
  }
}

/** Получить текст кнопки в зависимости от кода ошибки и режима ЛИС */
export const getButtonLabel = (errorCode?: string, isLisMode?: boolean) => {
  if (errorCode === CONFLICT_SIGNED_CODE) {
    return t('Отменить подписание и сохранить мой вариант')
  }

  if (errorCode === CONFLICT_VERSION_CODE) {
    return t('Заменить на мой вариант')
  }

  return isLisMode ? t('Сохранить черновик') : t('Сохранить')
}

/**  Функция для вычисления следующей версии lisVersion */
export const calculateLisVersion = (lisVersion?: number | null, initialReportLisVersion?: number | null): number => {
  if (lisVersion) {
    // Если lisVersion существует, проверяем, что версия из creationModalInitialReport больше, чем текущая
    if (initialReportLisVersion && initialReportLisVersion > lisVersion) {
      return initialReportLisVersion + 1
    }
    return lisVersion + 1
  }
  // Если lisVersion не существует, устанавливаем значение 1
  return 1
}
