import { Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import MarkerResultSelectContainer from 'entities/marker-result/ui/MarkerResultSelectContainer'
import { CaseReference } from 'features/cases/api/service'
import { markerResult, useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'
import { DictionaryValidator } from 'types/IValidators'

interface Props {
  /** Идентификатор ссылки на слайд случая. */
  caseReferencesSlides?: CaseReference[]
  /** Функция-валидатор для проверки пустых значений в форме или данных **/
  checkSelectEmpty: DictionaryValidator
}

export const MarkerResultContainer: FC<Props> = ({ caseReferencesSlides, checkSelectEmpty }) => {
  const { t } = useTranslation()
  const { form } = useReportCreationContext()

  useEffect(() => {
    const initialMarkerResultsList: markerResult[] = form.getFieldValue('markerResults') || []
    // Объединяем данные ReferencesSlides с данными по их маркерами
    const normalizedReferencesSlides = caseReferencesSlides?.map(({ caseSlideReferenceId, stain }) => {
      const localMarkerResult =
        initialMarkerResultsList.find((markerResult) => markerResult.caseSlideReferenceId === caseSlideReferenceId) ||
        {}
      // Невалидная окраска помечается null
      return { ...localMarkerResult, caseSlideReferenceId, stainId: stain?.id ?? null }
    })
    form.setFieldValue('markerResults', normalizedReferencesSlides)
  }, [])

  const markerResultsList = useWatch('markerResults', form) as markerResult[]

  return (
    <StyledFlex direction={'column'} width={'100%'} gap={8}>
      <Flex width={`100%`} justify={'space-between'} align={'center'}>
        <StyledHeader>{t('Стекло')}</StyledHeader>
        <StyledHeader>{t('Показатель')}</StyledHeader>
        <StyledHeader>{t('Результат') + '*'}</StyledHeader>
      </Flex>
      <Form.List name="markerResults">
        {(fields) => (
          <Flex vertical gap={8} width="100%">
            {fields.map(({ key, name }, index) => {
              const item = markerResultsList && markerResultsList[index]
              const { caseSlideReferenceId, stainId } = item
              return (
                <Flex width={`100%`} justify={'space-between'} align={'center'} key={key}>
                  <StyledTextElement>{caseSlideReferenceId}</StyledTextElement>
                  <StyledTextElement>{stainId || '—'}</StyledTextElement>
                  <MedicalReportFormItem
                    name={[name, 'markerResult']}
                    rules={[{ required: true, validator: checkSelectEmpty }]}
                    style={{ flex: 1, maxWidth: 120 }}
                  >
                    <MarkerResultSelectContainer stainId={stainId} />
                  </MedicalReportFormItem>
                </Flex>
              )
            })}
          </Flex>
        )}
      </Form.List>
    </StyledFlex>
  )
}

const StyledFlex = styled(Flex)`
  border: 1px solid var(--color-border-1);
  border-radius: 10px;
  padding: 8px;
`

const StyledTextElement = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  flex: 1;
`

const StyledHeader = styled.div`
  padding-bottom: 0;
  display: flex;
  height: 16px;
  margin-bottom: 4px;
  color: var(--color-text-3);
  flex: 1;
`
