import { FormListFieldData } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TNMSelectContainer from 'entities/tnm/ui/TNMSelectContainer'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC, useMemo } from 'react'

interface TNM {
  label: string
  formName: 'label' | 'lowercase'
  dictName: EDictionaryNS
  required: boolean
}

interface Props {
  blockIndex: number
  field: FormListFieldData
}

const TNMGroupContainer: FC<Props> = ({ field }) => {
  const { form } = useReportCreationContext()
  const icd10List = useWatch(['icd10DetailedList'], form)
  const neoadjuvantTherapy = icd10List?.[field.name]?.neoadjuvantTherapy
  const tnmPrefix = neoadjuvantTherapy ? 'y' : ''

  const tnmGroup = useMemo((): TNM[] => {
    const tnmPrefixUpper = tnmPrefix.toUpperCase()

    return [
      {
        dictName: EDictionaryNS[`TNM_${tnmPrefixUpper}PT` as EDictionaryNS],
        formName: 'label',
        label: tnmPrefix + 'pT',
        required: true,
      },
      {
        dictName: EDictionaryNS[`TNM_${tnmPrefixUpper}PN` as EDictionaryNS],
        formName: 'label',
        label: tnmPrefix + 'pN',
        required: true,
      },
      {
        dictName: EDictionaryNS[`TNM_${tnmPrefixUpper}PM` as EDictionaryNS],
        formName: 'label',
        label: tnmPrefix + 'pM',
        required: true,
      },
      {
        dictName: EDictionaryNS.TNM_G_CANCER_CELLS_GRADE,
        formName: 'lowercase',
        label: 'G',
        required: false,
      },
      {
        dictName: EDictionaryNS.TNM_R_RESIDUAL_TUMOR,
        formName: 'lowercase',
        label: 'R',
        required: false,
      },
      {
        dictName: EDictionaryNS.TNM_L_LYMPHATIC_INVASION,
        formName: 'lowercase',

        label: 'L',
        required: false,
      },
      {
        dictName: EDictionaryNS.TNM_V_VEIN_INVASION,
        formName: 'lowercase',

        label: 'V',
        required: false,
      },
      {
        dictName: EDictionaryNS.TNM_PN_PERINEURAL_INVASION,
        formName: 'label',
        label: 'Pn',
        required: false,
      },
    ]
  }, [neoadjuvantTherapy])

  return (
    <MedicalReportFormItem
      name={[field.name, 'tnmValidation']}
      dependencies={[
        ['icd10DetailedList', field.name, 'neoadjuvantTherapy'],
        ['icd10DetailedList', field.name, `${tnmPrefix}pT`],
        ['icd10DetailedList', field.name, `${tnmPrefix}pN`],
        ['icd10DetailedList', field.name, `${tnmPrefix}pM`],
      ]}
      rules={[
        {
          validator: async () => {
            const values = form.getFieldValue(['icd10DetailedList', field.name])
            const prefix = neoadjuvantTherapy ? 'y' : ''

            const requiredFields = [`${prefix}pT`, `${prefix}pN`, `${prefix}pM`]
            const missingRequired = requiredFields.some((field) => !values[field])

            if (missingRequired) {
              return Promise.reject(new Error(`Заполните обязательные поля ${requiredFields.join(', ')}`))
            }
            return Promise.resolve()
          },
        },
      ]}
      style={{ marginBottom: 0 }}
    >
      <div
        style={{
          display: 'grid',
          gap: 4,
          gridTemplateColumns: 'repeat(8, minmax(40px, 1fr))',
          position: 'relative',
        }}
      >
        {tnmGroup.map(({ dictName, formName, label, required }) => (
          <MedicalReportFormItem
            key={label}
            label={label}
            name={[field.name, formName === 'label' ? label : label.toLowerCase()]}
            rules={[{ required, validator: () => Promise.resolve() }]}
            style={{ marginBottom: 0 }}
          >
            <TNMSelectContainer dictType={dictName} />
          </MedicalReportFormItem>
        ))}
        <div
          style={{
            bottom: -20,
            color: 'var(--color-error)',
            fontSize: 12,
            left: 0,
            position: 'absolute',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        />
      </div>
    </MedicalReportFormItem>
  )
}

export default TNMGroupContainer
