import { IButtonData } from 'features/new-preview-panel/common'
import React from 'react'
import { IconElement, IIconElementProps, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
  &:hover > * {
    color: var(--color-text-1);
  }
`

const StyledIcon = styled(IconElement)`
  margin-right: 4px;
  color: var(--color-text-3);
  height: revert-layer;
`

const StyledSeparator = styled(IconElement)`
  background: var(--color-border-1);
  height: 16px;
  width: 1px;
`

const ButtonGroupContainer = styled.div`
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
/** Opacity для заблокированной кнопки */
const DISABLED_BUTTON_OPACITY = 0.4

// TODO Исправить ButtonContainer на обычный Button
const Button = ({ disabled, iconName, onClick, title }: IButtonData) => (
  <TooltipElement title={title}>
    <ButtonContainer
      data-testid={iconName}
      onClick={!disabled ? onClick : undefined}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? DISABLED_BUTTON_OPACITY : undefined }}
    >
      <StyledIcon name={iconName} size={'md'} />
    </ButtonContainer>
  </TooltipElement>
)

type Props = {
  /** объект доступных кнопок */
  buttons: IButtonData[]
  /** Хендлер кнопки закрытия */
  onClose: () => void
}

const ButtonsGroupHeader = ({ buttons, onClose }: Props) => (
  <ButtonGroupContainer data-testid={'preview-buttons-header'}>
    {buttons.map(({ customButton: CustomButton, disabled, iconName, onClick, title }, index) => (
      <React.Fragment key={iconName}>
        {CustomButton ? (
          CustomButton(
            <Button
              iconName={iconName as IIconElementProps['name']}
              title={title}
              onClick={onClick}
              disabled={disabled}
            />,
          )
        ) : (
          <Button
            iconName={iconName as IIconElementProps['name']}
            title={title}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {index < buttons.length - 1 && <StyledSeparator name={'verticalSeparator'} />}
      </React.Fragment>
    ))}
    <StyledSeparator name={'verticalSeparator'} />
    <StyledIcon style={{ cursor: 'pointer', marginLeft: 8 }} name={'cross16'} size={'md'} onClick={onClose} />
  </ButtonGroupContainer>
)

export default ButtonsGroupHeader
