import classNames from 'classnames'
import React, { CSSProperties, FC, MouseEvent } from 'react'

interface FlexProps {
  /**
   * Направление flex-контейнера.
   * - `'row'`: Элементы располагаются по горизонтали.
   * - `'column'`: Элементы располагаются по вертикали.
   * @default 'row'
   */
  direction?: 'row' | 'column'

  /**
   * Выравнивание элементов по поперечной оси.
   * - `'start'`: Выравнивание по началу контейнера.
   * - `'center'`: Выравнивание по центру.
   * - `'end'`: Выравнивание по концу контейнера.
   * - `'baseline'`: Выравнивание по базовой линии текста.
   * - `'stretch'`: Элементы растягиваются на всю высоту.
   * @default 'stretch'
   */
  align?: 'start' | 'center' | 'end' | 'baseline' | 'stretch'

  /**
   * Выравнивание элементов по основной оси.
   * - `'start'`: Выравнивание по началу контейнера.
   * - `'center'`: Выравнивание по центру.
   * - `'end'`: Выравнивание по концу контейнера.
   * - `'space-between'`: Равномерное распределение с отступами между элементами.
   * - `'space-around'`: Равномерное распределение с отступами по краям и между элементами.
   * - `'space-evenly'`: Элементы распределены равномерно с одинаковыми отступами.
   * @default 'start'
   */
  justify?: 'start' | 'center' | 'end' | 'space-between' | 'space-around' | 'space-evenly'

  /**
   * Перенос строк в контейнере.
   * - `'nowrap'`: Элементы не переносятся.
   * - `'wrap'`: Элементы переносятся на новую строку.
   * - `'wrap-reverse'`: Элементы переносятся в обратном порядке.
   * @default 'nowrap'
   */
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'

  /**
   * Расстояние между элементами в контейнере.
   * Можно указать число (в пикселях) или строку с единицами измерения CSS (например, `'16px'`).
   * @default 0
   */
  gap?: number | string

  /**
   * Дополнительные стили для контейнера.
   */
  style?: CSSProperties

  /**
   * Дополнительные CSS-классы для контейнера.
   */
  className?: string

  /**
   * Вложенные элементы внутри flex-контейнера.
   */
  children?: React.ReactNode

  /**
   * Упрощённое управление направлением.
   * Если `true`, то `direction` устанавливается в `'column'`.
   * @default false
   */
  vertical?: boolean

  /**
   * Ширина контейнера.
   */
  width?: number | string

  /**
   * Высота контейнера.
   */
  height?: number | string

  /**
   * Обработчик клика по контейнеру.
   * @param event MouseEvent объект события клика
   */
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}

/**
 * Гибкий контейнер с настраиваемыми стилями.
 * @param {FlexProps} props - Свойства компонента Flex.
 * @returns {JSX.Element} Гибкий flex-контейнер.
 */
const Flex: FC<FlexProps> = ({
  align = 'stretch',
  children,
  className,
  direction = 'row',
  gap = 0,
  height,
  justify = 'start',
  onClick,
  style,
  vertical = false,
  width,
  wrap = 'nowrap',
}: FlexProps): JSX.Element => {
  const classes = classNames('flex', className)
  const mergedStyle: CSSProperties = {
    alignItems: align === 'start' ? 'flex-start' : align === 'end' ? 'flex-end' : align,
    display: 'flex',
    flexDirection: vertical ? 'column' : direction,
    flexWrap: wrap,
    gap,
    height: typeof height === 'number' ? `${height}px` : height,
    justifyContent: justify === 'start' ? 'flex-start' : justify === 'end' ? 'flex-end' : justify,
    width: typeof width === 'number' ? `${width}px` : width,
    ...style,
  }

  return (
    <div className={classes} style={mergedStyle} onClick={onClick}>
      {children}
    </div>
  )
}

export default Flex
