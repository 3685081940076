import { Icd10SelectContainer } from 'entities/icd-10'
import { MorphologySelectContainer } from 'entities/tumor-type'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from 'shared/ui/kit/ui/Flex'
import { DictionaryValidator } from 'types/IValidators'

interface Props {
  /** Функция-валидатор для проверки пустых значений в форме или данных **/
  checkSelectEmpty: DictionaryValidator
}

const Icd10SingleBlock: FC<Props> = ({ checkSelectEmpty }) => {
  const { t } = useTranslation()

  return (
    <Flex
      vertical
      gap={8}
      style={{ border: '1px solid var(--color-border-1)', borderRadius: 10, padding: 8 }}
      width={376}
    >
      <MedicalReportFormItem label={t('МКБ-10')} name="icd10" rules={[{ required: true, validator: checkSelectEmpty }]}>
        <Icd10SelectContainer multiline={false} />
      </MedicalReportFormItem>
      <MedicalReportFormItem label={t('Морфологический код (МКБ-О)')} name="morphologyCode">
        <MorphologySelectContainer />
      </MedicalReportFormItem>
    </Flex>
  )
}

export default Icd10SingleBlock
