export type DictionaryId =
  | 'ICD_10'
  | 'MORPHOLOGY_CODE'
  | 'SERVICE'
  | 'MATERIAL_TYPE'
  | 'DIAGNOSTIC_PROCEDURE_TYPE'
  | 'CASE_RESEARCH_TYPE'
  | 'SITE'
  | 'PROCESS_NATURE'
  | 'STAIN'
  | 'MODALITY'
  | 'DEFECT_TYPE'
  | 'TOPOGRAPHY_CODE'
  | 'RESEARCH_REQUEST_REASON'
  | 'MARKER_RESULT'
  | 'BILLING_TYPE'
  | 'BILLABLE_SERVICE'
  | 'TNM_PT'
  | 'TNM_PN'
  | 'TNM_PM'
  | 'TNM_YPT'
  | 'TNM_YPN'
  | 'TNM_YPM'
  | 'TNM_G_CANCER_CELLS_GRADE'
  | 'TNM_R_RESIDUAL_TUMOR'
  | 'TNM_L_LYMPHATIC_INVASION'
  | 'TNM_V_VEIN_INVASION'
  | 'TNM_PN_PERINEURAL_INVASION'

export type DictionaryVersion = {
  /** название словарей */
  identifier: DictionaryId
  /** версия словарей */
  version: string
  /** дата/время обновления */
  updatedAt: string
  /** идентификатор воркспейса */
  workspaceId: string
  /** идентификатор словарей */
  hash: string
}

export type DictionaryItem = {
  shortName: string
  id: number
  name: string
  code?: string
  visible?: boolean
  groupName?: string
  mlClassName?: string
  mlClassColor?: string
  mlThreshold?: number
}

export type BillableServicesType = {
  /** Биллинговая услуга */
  billableService: IBillableService
  /** Идентификатор биллинговой услуги в медицинском отчете */
  medicalReportBillableServiceId?: number
  /** Общая сумма */
  total: number
  /** Количество единиц услуги */
  quantity?: number
  /** Цена за единицу услуги */
  price: number
}

/** Тип словарей для дефекта */
export type TDefectTypeDictionaryItem = Omit<DictionaryItem, 'visible' | 'groupName'> & {
  code: string
  /** Название класса машинного обучения, используемого для определения дефекта */
  mlClassName?: string
  /** Цвет, используемый для подсветки этого типа дефекта при визуализации результатов ML */
  mlClassColor?: string
  /** Пороговое значение уверенности для ML-определения этого типа дефекта (0-1) */
  mlThreshold?: number
}

/**  Тип данных для причины запроса в ЗнД */
export type IResearchRequestReasonDictionaryItem = {
  id: number
  code: string
}

/** Code для объектов из словаря причин ЗнД */
export enum ResearchRequestReasonCode {
  DIAG = 'DIAG',
  DEFECT = 'DEFECT',
}

/** Структура морфологии, которая приходит по вебсокету при поиске похожих */
export type Morphology = {
  /** короткое имя МКБ-О */
  shortName: string
  /** Название/описание морфологии */
  description: string
  /** Id морфологии */
  morphologyCodeId: number
}

export type ICD10Detailed = {
  /** Код по МКБ-10 */
  icd10: DictionaryItem
  /** Топографический код по МКБ-О */
  topographicalIcdO: DictionaryItem
  /** Морфологический код МКБ-О */
  morphologicalIcdO: DictionaryItem
  /** Характер процесса */
  processNature: DictionaryItem
  /** Классификация TNM */
  tnmClassification: string
  /** Стадия онкопатологии */
  neoadjuvantTherapy: boolean
  pT?: DictionaryItem
  pN?: DictionaryItem
  pM?: DictionaryItem
  ypT?: DictionaryItem
  ypN?: DictionaryItem
  ypM?: DictionaryItem
  g?: DictionaryItem
  r?: DictionaryItem
  l?: DictionaryItem
  v?: DictionaryItem
  Pn?: DictionaryItem
}
export type IBillableService = {
  /** Внутренний ID элемента словаря */
  id: number
  /** Название элемента словаря */
  name: string
  /** Код элемента словаря */
  code: string
  /** Краткое название элемента словаря */
  shortName: string
  /** Массив уникальных чисел (сложности) */
  complexities: number[]
  /** Массив объектов типа DictionaryItem */
  billingTypes: DictionaryItem[]
  /** Цена в минимальных единицах валюты (например, копейки или центы) */
  price: number
}
