/**
 * Проверяет валидность кода МКБ-10 из группы С00-96 с исключениями
 * @param {string} code - Код МКБ-10 для проверки (например, 'C23' или 'C23.4')
 * @returns {boolean} true если код валиден (входит в диапазон С00-96, исключая C33, C70-72, C81-96), иначе false
 * @example
 * isValidIcdCode('C23') // => true
 * isValidIcdCode('C33') // => false
 * isValidIcdCode('C71') // => false
 */
export const shouldShowOncologyFields = (code: string): boolean => {
  const cleanCode = code.split('.')[0]

  if (!cleanCode.startsWith('C')) {
    return false
  }

  const numericPart = parseInt(cleanCode.slice(1))

  // Проверяем исключения
  if (
    numericPart === 33 || // C33
    (numericPart >= 70 && numericPart <= 72) || // C70-72
    (numericPart >= 81 && numericPart <= 96) // C81-96
  ) {
    return false
  }

  // Проверяем, что код входит в диапазон C00-96
  return numericPart >= 0 && numericPart <= 96
}
