import { Divider } from 'antd'
import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { getFilteredServices } from 'features/reports/hooks/common'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDebounce from 'shared/lib/hooks/useDebounce'
import { IconElement, InputElement } from 'shared/ui/kit'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import { IBillableService } from 'types/IDictionary'

import ServicesOptions from './ServicesOptions'

const REQUEST_DELAY = 300

const SearchServices = ({ billingTypeName }: { billingTypeName?: string }) => {
  const { t } = useTranslation()
  const { form, setServicesModalVisible } = useReportCreationContext()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchQuery = useDebounce(searchQuery, REQUEST_DELAY)
  const dictionaryServices = useLiveQuery(() => getDataFromDB(EDictionaryNS.BILLABLE_SERVICE), []) as TDict<
    IBillableService[]
  >

  const complexity = form.getFieldValue('complexity')

  const { data: billableServices } = dictionaryServices || {}

  const filterServices = getFilteredServices(complexity, billingTypeName, billableServices)

  const onSearch = (query: string) => {
    setSearchQuery(query)
  }

  const searchedServices = filterServices?.filter(
    (service) =>
      service.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
      service.shortName.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
  )

  const handleCloseModal = () => setServicesModalVisible(false)

  return (
    <StyledModal
      title={
        <div style={{ display: 'flex' }}>
          <IconElement name="search" fill={'var(--color-text-3)'} />
          <StyledInput
            value={searchQuery}
            onChange={(e) => onSearch(e?.target?.value)}
            placeholder={t('Поиск по названию услуги или артикулу')}
          />
        </div>
      }
      visible={true}
      footer={null}
      onCancel={handleCloseModal}
    >
      {!!filterServices?.length && (
        <>
          <Divider style={{ margin: 0 }} />
          <div style={{ minHeight: 262 }}>
            <ListBoard>
              <ServicesOptions dictServices={searchedServices || filterServices} />
            </ListBoard>
          </div>
        </>
      )}
    </StyledModal>
  )
}

export default SearchServices

const StyledModal = styled(ModalComponent)`
  padding-bottom: 0;
  top: 54px;

  .ant-modal-body {
    width: 744px;
    max-height: calc(100vh - 54px);
    overflow-y: auto;
  }
  & .ant-modal-content .ant-modal-body {
    padding: 0 0 16px 16px;
  }
`

const StyledInput = styled(InputElement)`
  background: none;
  font-size: 14px;
  &.ant-input:focus,
  &.ant-input-focused {
    box-shadow: none !important;
  }
`

const ListBoard = styled.div`
  overflow: auto;
  align-items: center;
`
