import { Divider, Slider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useAnnotationsByCaseQuery } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { IntersectPanel } from 'features/annotations/ui/InteractPanel'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { CheckboxElement, InputNumberElement } from 'shared/ui/kit'
import { MAX_OPACITY } from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation, ISlideAnnotation } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { MIN_SELECTED_ANNOTATIONS_TO_MERGE } from 'viewer/map/layers/annotations/lib/helpers'
import { AnnotationFilterNotificationBlock } from 'viewer/map/layers/annotations/ui/filter/notification/AnnotationFilterNotificationBlock'
import { isMultiPolygonAnnotation, isNestedGeometryAnnotation, isPolygonAnnotation } from 'viewer/map/lib/utils'

import { PlatformAnnotationsList } from './PlatformAnnotationsList'

const StyledAnnotationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-collapse-header {
    padding: 16px 10px !important;
  }
`

const GappedCheckbox = styled(CheckboxElement)`
  padding: 8px 10px 10px 10px;
  margin-left: 0 !important;
`

const OpacityContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 16px 10px;
`

const OpacityTitle = styled.div`
  display: flex;
  align-items: center;
`

const StyledInputNumber = styled(InputNumberElement)`
  flex: 1;
  display: flex;
  align-items: center;
`

const StyledSlider = styled(Slider)`
  flex: 1;
  margin: 0 8px;
  height: 8px;

  .ant-slider-handle {
    background-color: #fff;
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`

const BottomElements = styled.div`
  display: flex;
  flex-direction: column;
`

/** Props for PlatformAnnotationsContainer */
type Props = {
  /** viewerId - id текущего вьювера */
  viewerId: TViewerId
  /** slideId - id текущего слайда */
  slideId: number
  /** caseId - id текущего случая */
  caseId: number
}

const getSlideAnnotations = (slideId: number, annotationsByCase?: ISlideAnnotation[]) => {
  const currentSlide = annotationsByCase?.find((slide) => slide.slideId === slideId)
  if (!currentSlide) return { slideAnnotationIds: [], slideAnnotations: [] }

  const slideAnnotations = currentSlide.annotations

  /** Получить массив slideAnnotationId, если аннотации найдены */
  const slideAnnotationIds = currentSlide.annotations?.map((annotation) => annotation.slideAnnotationId)
  return { slideAnnotationIds, slideAnnotations }
}

const PlatformAnnotationsContainer = ({ caseId, slideId, viewerId }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { annotationsIsVisible } = useTypedSelector((state) => state.annotations)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const selectedAnnotationId = useTypedSelector((state) => state.viewers[viewerId].viewer.selectedAnnotationsIds[0])
  const selectedAnnotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selectedAnnotationId])
  const { descriptionsVisibility } = useTypedSelector((state) => state.viewerPage)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const { data: annotationsByCase } = useAnnotationsByCaseQuery(caseId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const localStorageAllHiddenName = `isAllAnnotationHidden-${caseId}`
  const isAnnotationsFiltered = useTypedSelector((state) => state.annotations.isAnnotationsFiltered)
  const [isAllAnnotationsHidden, setIsAllAnnotationsHidden] = useState<boolean>(
    !!Number(localStorage.getItem(localStorageAllHiddenName)),
  )
  const isPolygonAnnotationSelected = isPolygonAnnotation(selectedAnnotation)
  const isMultiPolygonAnnotationSelected =
    isPolygonAnnotationSelected && isMultiPolygonAnnotation(selectedAnnotation?.data?.formattedFeature)
  const isNestedGeometryAnnotationSelected =
    isPolygonAnnotationSelected && isNestedGeometryAnnotation(selectedAnnotation?.data?.formattedFeature)

  const toggleInputFocus = (value: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))

  useEffect(() => {
    /** Эффект нужен, только при открытой IntersectPanel, условия выполнения === условию рендера  IntersectPanel !disunion */
    if (
      (selectedAnnotationsIds?.length >= MIN_SELECTED_ANNOTATIONS_TO_MERGE && isPolygonAnnotationSelected) ||
      isMultiPolygonAnnotationSelected ||
      isNestedGeometryAnnotationSelected
    ) {
      const slideAnnotationIds = getSlideAnnotations(slideId, annotationsByCase).slideAnnotationIds

      //  после мутаций с аннотациями (например объединение), обновляем выделенные id
      selectedAnnotationsIds?.length &&
        viewerDispatch(
          viewerSlice.actions.setSelectedAnnotationsIds(
            selectedAnnotationsIds.filter((id) => slideAnnotationIds?.includes(id)),
          ),
        )
    }
  }, [annotationType, annotationsByCase])

  useEffect(() => {
    const slideAnnotations = getSlideAnnotations(slideId, annotationsByCase).slideAnnotations
    if (slideAnnotations?.length) {
      const isHidden = !annotationsIsVisible?.length
      setIsAllAnnotationsHidden(isHidden)
      localStorage.setItem(localStorageAllHiddenName, isHidden ? '1' : '0')
    }
  }, [annotationsIsVisible, annotationsByCase])

  useEffect(() => {
    setIsAllAnnotationsHidden(!!Number(localStorage.getItem(localStorageAllHiddenName)))
  }, [caseId])

  const annotationsOpacity = useTypedSelector((state) => state.annotations.annotationsOpacity)
  const { isFastTravel } = useViewerPageProvided()

  const onOpacityChange = (value: number | string | null) => {
    viewerDispatch(annotationsSlice.actions.setPrevAnnotationsOpacity(Number(annotationsOpacity)))
    viewerDispatch(annotationsSlice.actions.setAnnotationsOpacity(Number(value)))
  }

  const disableInteractions = useMemo(() => {
    const slideAnnotations = getSlideAnnotations(slideId, annotationsByCase).slideAnnotations
    if (!annotationsByCase || !slideAnnotations) return true

    return slideAnnotations.some(
      (annotation) =>
        selectedAnnotationsIds.includes(annotation.slideAnnotationId) &&
        [AnnotationType.POINT, AnnotationType.RULER, AnnotationType.ARROW].includes(annotation.type),
    )
  }, [selectedAnnotationsIds])

  useHotkeys('H', () => !isFastTravel && toggleAnnotationsVisibility(!!annotationsIsVisible), [annotationsIsVisible])

  const toggleDescriptionVisibility = useCallback(
    (isHidden: boolean) => {
      dispatch(viewerPageSlice.actions.setDescriptionsVisibility(!isHidden))
    },
    [dispatch],
  )

  const toggleAnnotationsVisibility = useCallback(
    (isHidden: boolean) => {
      const annotationIds = getSlideAnnotations(slideId, annotationsByCase).slideAnnotationIds

      if (!annotationsIsVisible?.length) {
        setIsAllAnnotationsHidden(isHidden)
        localStorage.setItem(localStorageAllHiddenName, isHidden ? '1' : '0')
      }

      dispatch(annotationsSlice.actions.setAnnotationsIsVisible(isHidden ? null : annotationIds || []))
      if (isHidden) {
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
        viewerDispatch(viewerSlice.actions.setBuffer())
      }
    },
    [annotationsByCase],
  )

  const isIntersectPanelVisible =
    !selectedAnnotationsIds.find((it) => {
      const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, it])
      return annotation?.userId !== currentUserId
    }) &&
    (selectedAnnotationsIds?.length >= MIN_SELECTED_ANNOTATIONS_TO_MERGE ||
      isMultiPolygonAnnotationSelected ||
      isNestedGeometryAnnotationSelected)

  return (
    <StyledAnnotationContainer>
      <div id="right-panel-top-divider">
        {isAnnotationsFiltered && <AnnotationFilterNotificationBlock />}
        <Divider />
      </div>
      <div style={{ height: '100%' }}>
        {annotationsByCase?.length ? (
          <PlatformAnnotationsList annotations={annotationsByCase} slideId={slideId} caseId={caseId} />
        ) : (
          ''
        )}
      </div>
      <BottomElements id="right-panel-bottom-elements">
        <Divider />
        <GappedCheckbox
          id="hide-annotation-caption-checkbox"
          style={{ paddingBottom: 0 }}
          checked={!descriptionsVisibility}
          onChange={(e) => toggleDescriptionVisibility(e.target.checked)}
        >
          {t('Скрыть подписи к аннотациям')}
        </GappedCheckbox>
        <GappedCheckbox
          id="hide-annotation-checkbox"
          data-testid="annotation-visible-checkbox"
          checked={isAllAnnotationsHidden}
          onChange={(e) => toggleAnnotationsVisibility(e.target.checked)}
        >
          {t('Скрыть все аннотации')}
        </GappedCheckbox>
        <OpacityContainerWrapper
          id="opacity"
          onFocus={() => toggleInputFocus(true)}
          onBlur={() => toggleInputFocus(false)}
        >
          <OpacityTitle>{t('Прозрачность')}</OpacityTitle>
          <StyledSlider
            min={0}
            max={MAX_OPACITY}
            tipFormatter={(value) => `${value}%`}
            onChange={onOpacityChange}
            value={annotationsOpacity}
          />
          <StyledInputNumber
            min={0}
            max={MAX_OPACITY}
            data-testid="opacity-input"
            formatter={(value) => `${value}%`}
            value={annotationsOpacity}
            onChange={onOpacityChange}
          />
        </OpacityContainerWrapper>
      </BottomElements>
      {isIntersectPanelVisible && (
        <IntersectPanel
          annotationsIds={selectedAnnotationsIds}
          isMultiPolygonAnnotation={isMultiPolygonAnnotationSelected}
          isNestedGeometryAnnotation={isNestedGeometryAnnotationSelected}
          caseId={caseId}
          slideId={slideId}
          disunion={isPolygonAnnotationSelected && selectedAnnotationsIds?.length === 1}
          disabled={disableInteractions}
        />
      )}
    </StyledAnnotationContainer>
  )
}

export default PlatformAnnotationsContainer
