import { get } from 'shared/api'
import { DictionaryId, DictionaryItem, DictionaryVersion } from 'types/IDictionary'

const baseUrl = '/dictionary'

export enum DictionaryEndpoints {
  ICD_10 = '/icd-10',
  MORPHOLOGY_CODE = '/morphologies',
  SERVICE = '/services',
  MATERIAL_TYPE = '/material-types',
  DIAGNOSTIC_PROCEDURE_TYPE = '/diagnostic-procedure-types',
  DEFECT_TYPE = '/defect-types',
  CASE_RESEARCH_TYPE = '/case-research-types',
  SITE = '/sites',
  PROCESS_NATURE = '/process-natures',
  STAIN = '/stains',
  MODALITY = '/modalities',
  TOPOGRAPHY_CODE = '/topographies',
  RESEARCH_REQUEST_REASON = '/research-request-reasons',
  MARKER_RESULT = '/marker-result',
  BILLING_TYPE = '/billing-type',
  BILLABLE_SERVICE = '/billable-services',
  TNM_PT = '/tnm/pt',
  TNM_PN = '/tnm/pn',
  TNM_PM = '/tnm/pm',
  TNM_YPT = '/tnm/ypt',
  TNM_YPN = '/tnm/ypn',
  TNM_YPM = '/tnm/ypm',
  TNM_G_CANCER_CELLS_GRADE = '/tnm/g-cancer-cells-grade',
  TNM_R_RESIDUAL_TUMOR = '/tnm/r-residual-tumor',
  TNM_L_LYMPHATIC_INVASION = '/tnm/l-lymphatic-invasion',
  TNM_V_VEIN_INVASION = '/tnm/v-vein-invasion',
  TNM_PN_PERINEURAL_INVASION = '/tnm/pn-perineural-invasion',
}

export enum EDictionaryNS {
  ICD_10 = 'ICD_10',
  MORPHOLOGY_CODE = 'MORPHOLOGY_CODE',
  SERVICE = 'SERVICE',
  MATERIAL_TYPE = 'MATERIAL_TYPE',
  DIAGNOSTIC_PROCEDURE_TYPE = 'DIAGNOSTIC_PROCEDURE_TYPE',
  CASE_RESEARCH_TYPE = 'CASE_RESEARCH_TYPE',
  SITE = 'SITE',
  PROCESS_NATURE = 'PROCESS_NATURE',
  STAIN = 'STAIN',
  MODALITY = 'MODALITY',
  DEFECT_TYPE = 'DEFECT_TYPE',
  TOPOGRAPHY_CODE = 'TOPOGRAPHY_CODE',
  RESEARCH_REQUEST_REASON = 'RESEARCH_REQUEST_REASON',
  MARKER_RESULT = 'MARKER_RESULT',
  BILLING_TYPE = 'BILLING_TYPE',
  BILLABLE_SERVICE = 'BILLABLE_SERVICE',
  TNM_PT = 'TNM_PT',
  TNM_PN = 'TNM_PN',
  TNM_PM = 'TNM_PM',
  TNM_YPT = 'TNM_YPT',
  TNM_YPN = 'TNM_YPN',
  TNM_YPM = 'TNM_YPM',
  TNM_G_CANCER_CELLS_GRADE = 'TNM_G_CANCER_CELLS_GRADE',
  TNM_R_RESIDUAL_TUMOR = 'TNM_R_RESIDUAL_TUMOR',
  TNM_L_LYMPHATIC_INVASION = 'TNM_L_LYMPHATIC_INVASION',
  TNM_V_VEIN_INVASION = 'TNM_V_VEIN_INVASION',
  TNM_PN_PERINEURAL_INVASION = 'TNM_PN_PERINEURAL_INVASION',
}

const getDictionaryInfo = async (workspaceId?: string) => {
  const res = await get<DictionaryVersion[]>({
    url: `${baseUrl}/info`,
    workspaceId,
  })
  return res
}

const getDictionaryItems = async (identifier: DictionaryId) => {
  const url = baseUrl + DictionaryEndpoints[identifier]

  if (url) {
    return await get<DictionaryItem[]>({
      url,
    })
  }

  return
}

export const dictionaryService = { getDictionaryInfo, getDictionaryItems }
