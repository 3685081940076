import { useTypedSelector } from 'app/redux/lib/selector'
import { useReportManagement } from 'features/reports/hooks/useReport'
import { selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import React, { FC, RefObject } from 'react'
import Flex from 'shared/ui/kit/ui/Flex'
import { ModalHandle, ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components'
import ISource from 'types/ISource'

import ReportCreationFormLeftContainer from './left/ReportCreationFormLeftContainer'
import ReportResizableForm from './ReportResizableForm'
import ReportCreationFormRight from './right/ReportCreationFormRight'

export type ReportCreationModalProps = {
  caseId: number
  source: ISource
  modalRef: RefObject<ModalHandle>
}

const StyledModalProvider = styled(ModalProvider)`
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-close {
      top: 2px;
      right: 2px;
    }
    .ant-modal-body {
      height: 100%;
    }
  }
`

const ReportCreationModalContainer: FC<ReportCreationModalProps> = ({ caseId, modalRef, source }) => {
  const creationModalInitialReport = useTypedSelector((state) => state.reports.creationModalInitialReport)
  const index = useTypedSelector(selectedReportByCaseSelector(caseId))

  const { isLoading, onCancelModal, onCloseModal, onDelete, onSave } = useReportManagement({
    caseId,
    creationModalInitialReport,
    modalRef,
    selectedReportIndex: index,
    source,
  })

  return (
    <StyledModalProvider
      ref={modalRef}
      destroyOnClose
      closable={false}
      onCancel={onCancelModal}
      footer={null}
      width="fit-content"
      centered={true}
      bodyStyle={{
        padding: 0,
      }}
      style={{ height: '100%', maxWidth: '100%', overflow: 'hidden' }}
    >
      <ReportResizableForm>
        <Flex style={{ height: '100%', overflow: 'hidden' }}>
          <ReportCreationFormLeftContainer
            onCloseModal={onCloseModal}
            caseId={caseId}
            onSave={onSave}
            onCancel={onCancelModal}
            onDelete={onDelete}
            isSaving={isLoading.isReportCreating || isLoading.isReportUpdating}
            isDeleting={isLoading.isRemoveLoading}
          />
          <ReportCreationFormRight caseId={caseId} />
        </Flex>
      </ReportResizableForm>
    </StyledModalProvider>
  )
}

export default ReportCreationModalContainer
