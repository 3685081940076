import { DictionaryItem } from 'types/IDictionary'

import Icd10SelectElement from './ui/Icd10SelectElement'

type Props = {
  /** При значении true фильтрует коды ICD-10 по их статусу видимости. По умолчанию true. */
  filterByVisible?: boolean
  /** При значении true включает многострочный режим отображения. По умолчанию true. */
  multiline?: boolean
}

const Icd10SelectContainer = ({ filterByVisible = true, multiline = true, ...props }: Props) => (
  <Icd10SelectElement
    multiline={multiline}
    {...props}
    filterCb={filterByVisible ? (icd10: DictionaryItem) => !!icd10.visible : undefined}
  />
)

export default Icd10SelectContainer
