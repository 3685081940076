import { Form, FormInstance } from 'antd'
import { endOfDay, isAfter } from 'date-fns'
import { DepartmentsSelect } from 'entities/department'
import { DiagnosticProcedureTypeSelectElement } from 'entities/diagnosticProcedureType'
import { Icd10SelectContainer } from 'entities/icd-10'
import { SiteSelectContainer } from 'entities/site'
import { useCaseQuery } from 'features/cases/api/query'
import { IFile } from 'features/cases/api/service'
import { casesSlice } from 'features/cases/model/casesSlice'
import { notices } from 'features/notices'
import { PatientFormModal } from 'features/patients/ui/PatientFormModal'
import { PatientSelect } from 'features/patients/ui/PatientSelect'
import { viewerPageSlice } from 'pages/viewer'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { parseErrorsFromServerForm } from 'shared/lib/form'
import { ButtonElement, InputElement, MaskedDatePickerElement, TextAreaElement } from 'shared/ui/kit'
import DragAndDrop from 'shared/ui/kit/ui/DragAndDrop'
import styled from 'styled-components/macro'
import { ICasePathologicalInfo, IModalities } from 'types/ICase'
import IPatient, { IPatientDTO } from 'types/IPatient'

const { Item, useForm } = Form

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: inherit;
  min-width: 592px;
`
const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: 1px solid var(--color-border-1);

  .ant-form-item {
    margin-bottom: 0;
  }
`

const StyledItem = styled(Item)`
  .ant-select {
    width: 100%;
  }
  .ant-form-item-required {
    &::before {
      content: '' !important;
      display: none !important;
    }
    &::after {
      width: 100%;
      content: '*' !important;
      display: inline-block !important;
      margin-left: 8px !important;
      color: var(--color-text-1) !important;
      font-size: 12px !important;
      font-family: SimSun, sans-serif !important;
      line-height: 1 !important;
    }
  }
  .ant-form-item-label > label {
    color: var(--color-text-1);
  }
`

const SubmitSection = styled.div`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  align-items: center;
`

const Inner = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
`

export type CaseFormDataType = {
  caseType?: 'PATHOLOGICAL' | 'RADIOLOGICAL'
  name: string
  patient?: IPatientDTO | IPatient
  casePathologicalInfo: ICasePathologicalInfo
  materialTakeoutDate?: string | Date
  siteSpecificMetadata?: {
    type?: 'PROSTATE_CANCER_METADATA' | string
  } & Record<string, any>
  modalities: IModalities[]
  /** Срочность */
  urgent: boolean
  caseId?: number
}

export type CaseFormProps = {
  onSave: (data: CaseFormDataType, currFiles: IFile[], deletedFiles?: IFile[]) => Promise<void>
  onCancel?: () => void
  isEdit?: boolean
  initialValues?: Partial<CaseFormDataType>
  caseRecordDocs?: IFile[]
  form?: FormInstance
  initialError?: any
  onOpenPatientModal?: () => void
}

export const CaseForm = ({
  caseRecordDocs,
  form,
  initialError,
  initialValues,
  isEdit,
  onCancel,
  onOpenPatientModal,
  onSave,
}: CaseFormProps) => {
  const [isLoading, setLoading] = useState(false)
  const [currFiles, setCurrFiles] = useState<IFile[]>(caseRecordDocs || [])
  const dispatch = useDispatch()

  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }

  const { t } = useTranslation()

  const { data: caseRecord } = useCaseQuery({ caseId: initialValues?.caseId, source: 'PLATFORM' })

  const onFormFinish = useCallback(
    async (data: CaseFormDataType, newFiles: IFile[], deletedFiles?: IFile[]) => {
      try {
        setLoading(true)
        await onSave(
          {
            ...data,
            casePathologicalInfo: {
              ...data.casePathologicalInfo,
              department: data.casePathologicalInfo.department || null,
              diagnosticProcedureType: data.casePathologicalInfo.diagnosticProcedureType || null,
              incomingIcd10: data.casePathologicalInfo.incomingIcd10,
              sites:
                data.casePathologicalInfo?.sites && data.casePathologicalInfo?.sites[0]
                  ? [{ container: null, site: data.casePathologicalInfo?.sites[0]?.site }]
                  : null,
            },
            caseType: 'PATHOLOGICAL',
            modalities: data.modalities ? { ...data.modalities } : [],
            urgent: !!caseRecord?.urgent,
          },
          newFiles,
          deletedFiles,
        )
      } catch (e: any) {
        setLoading(false)
        if (e?.response?.status === 400) {
          ;(isEdit ? localForm : form)?.setFields(parseErrorsFromServerForm(e.response.data.errors))
        }
        if (e?.response?.status === 409) {
          ;(isEdit ? localForm : form)?.setFields([
            { errors: [t('Случай с таким именем уже существует')], name: 'name' },
          ])
        }
        if (e?.response?.status === 418) {
          notices.error({
            message: t('Превышено максимальное количество медицинских случаев в рамках лицензии'),
          })
        }
        if (e?.response?.status === 422) {
          ;(isEdit ? localForm : form)?.setFields([
            {
              errors: [t('Загрузка файлов подобного формата недопустима')],
              name: 'files',
            },
          ])
        }
      }
    },
    [onSave],
  )

  const [localForm] = useForm()

  const patientHandler = (patient: IPatientDTO) => {
    const fields = (form || localForm)?.getFieldsValue()
    const fieldsArr = []
    for (const [key, value] of Object.entries(fields)) {
      fieldsArr.push({ name: key, value })
    }
    if (patient) {
      ;(form || localForm).setFields([
        ...fieldsArr,
        {
          name: 'patient',
          value: { ...patient },
        },
      ])
    }
  }

  useEffect(() => {
    setIsAnyInputFocusing(true)
    if (initialError?.response?.status === 422) {
      ;(isEdit ? localForm : form)?.setFields([
        {
          errors: [t('Загрузка файлов подобного формата недопустима')],
          name: 'files',
        },
      ])
    }
    return () => setIsAnyInputFocusing(false)
  }, [])

  return (
    <Outer>
      <Inner>
        <Form
          form={isEdit ? localForm : form}
          layout="horizontal"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 20 }}
          colon={false}
          initialValues={initialValues}
          onFieldsChange={(changed) => {
            ;(isEdit ? localForm : form)?.setFields(changed.map((field) => ({ ...field, errors: [] })))
          }}
        >
          <StyledItem name={'name'} label={t('Номер случая')} required>
            <InputElement data-testid="case-name-inp" placeholder={t('Например, ID случая из внешней ИС')} />
          </StyledItem>
          <StyledItem name={['patient']} label={t('Пациент')} required>
            <PatientSelect data-testid="patient-name-inp" onOpenPatientModal={onOpenPatientModal} />
          </StyledItem>
          <StyledItem name={['casePathologicalInfo', 'incomingIcd10']} label={t('МКБ-10')} required>
            <Icd10SelectContainer data-testid="icd10-name-inp" filterByVisible={false} />
          </StyledItem>
          <StyledItem
            data-testid="case-pathological-info-inp"
            name={['casePathologicalInfo', 'diagnosticProcedureType']}
            label={t('Способ получения материала')}
            required
          >
            <DiagnosticProcedureTypeSelectElement data-testid="diagnostic-procedure-type-select" />
          </StyledItem>
          {/* <StyledItem name={'researchDate'} label={'Дата исследования'} required>
            <MaskedDatePickerElement disabledDate={(current) => isAfter(current, endOfDay(new Date()))} />
          </StyledItem> */}
          <StyledItem
            name={['casePathologicalInfo', 'sites']}
            data-testid={'casePathologicalInfo-dict-select'}
            label={t('Локализация патологич. процесса')}
            required
          >
            <SiteSelectContainer data-testid={'casePathologicalInfo-dict-select'} placeholder={t('Не выбран')} />
          </StyledItem>
          <StyledItem name={'materialTakeoutDate'} label={t('Дата забора материала')}>
            <MaskedDatePickerElement
              data-testid={'materialTakeoutDate-dict-select'}
              disabledDate={(current) => isAfter(current, endOfDay(new Date()))}
            />
          </StyledItem>
          <StyledItem name={['casePathologicalInfo', 'department']} label={t('Отделение направившее материал')}>
            <DepartmentsSelect />
          </StyledItem>
          {/* COMPLETED */}
          <StyledItem name={['casePathologicalInfo', 'clinicalInfo']} label={t('Диагноз основного заболевания')}>
            <TextAreaElement
              data-testid="casePathologicalInfo-inp"
              placeholder={t('Заполняется при наличии')}
              autoSize={{ maxRows: 5, minRows: 3 }}
            />
          </StyledItem>
          <StyledItem name={['casePathologicalInfo', 'additionalInfo']} label={t('Доп. клинические сведения')}>
            <TextAreaElement
              data-testid="addClinicalInfo-inp"
              placeholder={t('Заполняется при наличии')}
              autoSize={{ maxRows: 5, minRows: 3 }}
            />
          </StyledItem>
          <StyledItem name={['casePathologicalInfo', 'macroDescription']} label={t('Макроописание')}>
            <TextAreaElement
              data-testid="macroDescription-inp"
              placeholder={t('Заполняется при наличии')}
              autoSize={{ maxRows: 5, minRows: 3 }}
            />
          </StyledItem>
          <StyledItem label={t('Клиническая информация')}>
            <DragAndDrop defaultFileList={currFiles} setCurrFiles={setCurrFiles} />
          </StyledItem>
        </Form>
      </Inner>
      <Footer>
        <Item
          wrapperCol={{
            sm: { offset: 10 },
          }}
        >
          <SubmitSection>
            {isEdit && (
              <ButtonElement style={{ gridArea: 'left' }} onClick={() => onCancel && onCancel()}>
                {t('Отменить')}
              </ButtonElement>
            )}

            <ButtonElement
              data-testid={'createCaseCompletion-btn'}
              style={{ gridArea: 'right' }}
              type="primary"
              onClick={() => {
                onFormFinish(
                  (isEdit ? localForm : form)?.getFieldsValue() as CaseFormDataType,
                  currFiles.filter((item) => item.originFileObj),
                  caseRecordDocs?.filter((oldItem) => !currFiles.find((newItem) => newItem.uid === oldItem.uid)),
                )
              }}
              loading={isLoading}
            >
              {isEdit ? t('Сохранить') : t('Создать случай')}
            </ButtonElement>
          </SubmitSection>
        </Item>
      </Footer>
      <PatientFormModal
        closeHandler={() => dispatch(casesSlice.actions.setPatientModal(false))}
        onSuccess={patientHandler}
      />
    </Outer>
  )
}
