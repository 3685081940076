import { Divider, Form } from 'antd'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { endOfDay, isAfter } from 'date-fns'
import patientService from 'features/patients/api/service'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HttpStatus } from 'shared/api/httpStatusCode'
import { formatDateWithNoTime } from 'shared/lib/date'
import { parseErrorsFromServerForm } from 'shared/lib/form'
import { ButtonElement, InputElement, MaskedDatePickerElement, SegmentElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { IPatientDTO } from 'types/IPatient'

const { Item, useForm } = Form

const SubmitSection = styled.div`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  align-items: center;
`

const StyledItem = styled(Item)`
  &.ant-form-item .ant-form-item-label > label {
    color: var(--color-text-1);
  }
`

type PatientFormProps = {
  isEdit?: boolean
  initialValues?: Partial<IPatientDTO>
  onSuccess?: (patient?: IPatientDTO) => void
}

export const PatientForm = ({ initialValues, isEdit, onSuccess }: PatientFormProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = useForm()
  const { t } = useTranslation()
  const theme = useThemeContext()
  const genderOptions = [
    {
      label: t('Мужской'),
      value: 'MALE',
    },
    {
      label: t('Женский'),
      value: 'FEMALE',
    },
  ]

  const onOk = async () => {
    try {
      const data = form.getFieldsValue() as IPatientDTO
      setLoading(true)
      const patient = await patientService.createPatient({
        ...data,
        birthDate: data?.birthDate && formatDateWithNoTime(new Date(data?.birthDate)),
      })
      setLoading(false)
      onSuccess && onSuccess(patient)
    } catch (e: any) {
      setLoading(false)
      const errorStatus = e?.response?.status

      if (errorStatus === HttpStatus.BAD_REQUEST) {
        form.setFields(parseErrorsFromServerForm(e.response.data.errors))
      } else if (errorStatus === HttpStatus.CONFLICT) {
        form.setFields([{ errors: [t('Пациент с данным ID уже существует')], name: ['externalId'] }])
      }
    }
  }
  const onCancel = () => {
    onSuccess && onSuccess()
  }
  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 20 }}
      colon={false}
      initialValues={initialValues}
      onFieldsChange={(changed) => {
        form.setFields(changed.map((field) => ({ ...field, errors: [] })))
      }}
    >
      <Item label={' '}>
        <TitleElement level={1} style={{ fontWeight: 600, gridArea: 'code', marginBottom: '12px' }}>
          {t('Создание пациента')}
        </TitleElement>
        <Divider />
      </Item>
      <StyledItem name={'fullname'} label={t('ФИО')}>
        <InputElement data-testid="new-patient-name-inp" maxLength={257} />
      </StyledItem>
      <StyledItem name={'sex'} label={t('Пол')}>
        <SegmentElement
          data-testid={'male-dict-select'}
          colorTheme={theme.theme}
          options={genderOptions}
          defaultValue={'MALE'}
        />
      </StyledItem>
      <StyledItem name={'birthDate'} label={t('День рождения')}>
        <MaskedDatePickerElement
          data-testid="new-patient-bithdate-inp"
          disabledDate={(current) => isAfter(current, endOfDay(new Date()))}
        />
      </StyledItem>
      <StyledItem name={'externalId'} label={t('ID пациента')}>
        <InputElement data-testid="new-patient-externalId-inp" maxLength={257} />
      </StyledItem>
      <Item label={' '}>
        <Divider />
      </Item>
      <Item
        wrapperCol={{
          sm: { offset: 10, span: 20 },
        }}
      >
        <SubmitSection>
          <ButtonElement data-testid="cancel-patient-creation-btn" onClick={onCancel} style={{ gridArea: 'left' }}>
            {t('Отменить')}
          </ButtonElement>
          <ButtonElement
            data-testid="complete-patient-creation-btn"
            type="primary"
            onClick={onOk}
            style={{ gridArea: 'right' }}
            loading={loading}
          >
            {isEdit ? t('Сохранить') : t('Создать пациента')}
          </ButtonElement>
        </SubmitSection>
      </Item>
    </Form>
  )
}
