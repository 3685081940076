import { Typography } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import { useFormValidators } from 'features/reports/hooks/useValidations'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextAreaElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { StructuredDescription } from 'types/ICase'

interface Props {
  /** Данные кейса */
  data: StructuredDescription[] | null
  /** Имя поля формы */
  name: NamePath
  /** Заголовок формы */
  label: string
}

const { Title } = Typography

const ReportStructuredDescriptionForm: FC<Props> = ({ data, label, name }) => {
  const { t } = useTranslation()
  const { form, isSigning } = useReportCreationContext()
  const [localData, setLocalData] = useState<StructuredDescription[]>(data || [])

  useEffect(() => {
    if (data && localData.length === 0) {
      setLocalData(data)
    }
  }, [data])

  const handleTextChange = (bioMaterialId: number, value: string) => {
    const updatedData = localData.map((item) =>
      item.bioMaterialId === bioMaterialId ? { ...item, text: value } : item,
    )
    setLocalData(updatedData)
  }

  useEffect(() => {
    form.setFieldValue(name, localData)
    form.validateFields([name])
  }, [localData])

  const {
    validators: { descriptionValidator },
  } = useFormValidators(isSigning)

  return (
    <MedicalReportFormItem
      style={{ flexDirection: 'column' }}
      label={label}
      name={name}
      normalize={() => localData}
      rules={[{ required: true, validator: descriptionValidator }]}
    >
      <StructuredDescriptionContainer>
        {localData?.map((item: StructuredDescription) => (
          <ContainerRow key={item.bioMaterialId}>
            <StyledDescriptionTitle>{`${t('Контейнер')} ${item.bioMaterialId}`}</StyledDescriptionTitle>
            <HiddenTextArea
              id={item.bioMaterialId.toString()}
              value={item.text || ''}
              autoSize={{ maxRows: 6, minRows: 2 }}
              bordered={false}
              onChange={(e) => handleTextChange(item.bioMaterialId, e.target.value)}
            />
          </ContainerRow>
        ))}
      </StructuredDescriptionContainer>
    </MedicalReportFormItem>
  )
}

export default ReportStructuredDescriptionForm

const ContainerRow = styled.div`
  align-items: center;
  margin-bottom: 8px;
`

const StyledDescriptionTitle = styled(Title)`
  font-size: 14px !important;
  line-height: 18px !important;
  margin-bottom: 0px !important;
  padding-left: 8px;
`

const HiddenTextArea = styled(TextAreaElement)`
  &.ant-input {
    background: none;
    resize: none;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
  }
  &.ant-input:focus {
    box-shadow: none;
  }
`

const StructuredDescriptionContainer = styled.div`
  width: 100%;
  background: var(--color-bg-3);
  border-radius: 5px;
  padding: 8px 0px;
  gap: 8px;
  min-height: 52px;
  max-height: 182px;
  overflow-y: auto;
`
