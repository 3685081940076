import React, { FC } from 'react'
import { TitleElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'

import ReportActionButton from './ReportActionButton'

const Wrapper = styled(Flex)`
  height: 48px;
  padding: 12px 16px;
  width: 100%;
`

interface Props {
  /** Функция, вызываемая при закрытие заключения. */
  onCancel: () => void
  /** Заголовок формы, имя кейса. */
  title: string
  /** Тип финансирования. */
  billingType: string | null
  /** Является ли интеграция DIT */
  isDZM: boolean
}

const ReportCreationFormLeftHeader: FC<Props> = ({ billingType, isDZM, onCancel, title }) => (
  <Wrapper justify="space-between" align="center">
    <TitleElement level={3}>
      {title} {isDZM && billingType}
    </TitleElement>
    <Actions onCancel={onCancel} />
  </Wrapper>
)

export default ReportCreationFormLeftHeader

const Actions = ({ onCancel }: { onCancel: () => void }) => (
  <Flex gap={8}>
    {/*<ReportActionButton iconName="minimizeSmall" onClick={() => {}} />*/}
    {/*<ReportActionButton iconName="newWindowBold" onClick={() => {}} />*/}
    <ReportActionButton iconName="cross16" onClick={onCancel} />
  </Flex>
)
