import { CaseBiomaterial } from 'features/cases/api/service'
import { StructuredDescription } from 'types/ICase'

/**
 * Хук для объединения данных caseBiomaterial и structuredDescription
 */
export const useMergedStructuredDescriptions = (
  caseBiomaterial?: CaseBiomaterial[],
  structuredDescription?: StructuredDescription[],
) => {
  if (!caseBiomaterial) return []

  // Создаем Map, где ключ — bioMaterialId, значение — text
  const descriptionsMap = new Map(structuredDescription?.map(({ bioMaterialId, text }) => [bioMaterialId, text]) ?? [])

  // Формируем итоговый массив с bioMaterialId и соответствующим text (или null)
  return caseBiomaterial.map(({ bioMaterialId }) => ({
    bioMaterialId,
    text: descriptionsMap.get(bioMaterialId) ?? null,
  }))
}
