import { ButtonProps } from 'antd/es/button/button'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const StyledButton = styled(ButtonElement)`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0;
  gap: 1px;
  height: 16px;
  color: var(--color-text-3);
  width: fit-content;
  font-size: 11px;

  &:hover {
    opacity: 1 !important;
  }
`

interface Props {
  onClick: ButtonProps['onClick']
}

const Icd10BlockDeleteButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <StyledButton type="text" icon={<IconElement name="delete" size="md" />} onClick={onClick}>
      {t('Удалить')}
    </StyledButton>
  )
}

export default Icd10BlockDeleteButton
