import { Form, FormInstance, FormProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { reportsSlice } from 'features/reports/model/reportsSlice'
import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ValidateMessages } from 'types/Form'
import { StructuredDescription } from 'types/ICase'
import { BillableServicesType, DictionaryItem, ICD10Detailed } from 'types/IDictionary'
import IReport from 'types/IReport'
import IUser from 'types/IUser'

interface ReportCreationContextProps {
  /** Флаг, указывающий наличие ошибок валидации */
  hasValidateErrors: boolean
  /** Инстанс формы */
  form: FormInstance<ReportFormData>
  /** Обработчик изменения значений полей формы. */
  onValuesChange: FormProps<ReportFormData>['onValuesChange']
  /** Опциональное начальное заключение, которое может быть частично заполнено и используется для предзаполнения формы. */
  initialReport: Partial<IReport>
  /** Флаг, указывающий видимость модального окна */
  isActionModalVisible: boolean
  /** Функция для изменения видимости модального окна */
  setIsActionModalVisible: Dispatch<SetStateAction<boolean>>
  /** Флаг, указывающий наличие конфликта */
  isConflictError: boolean
  /** Функция для изменения наличия конфликта */
  setIsConflictError: Dispatch<SetStateAction<boolean>>
  /** Сообщение об ошибке при сохранении заключения */
  saveReportError: string
  /** Функция для изменения сообщения об ошибке при сохранении */
  setSaveReportError: Dispatch<SetStateAction<string>>
  /** Сообщение об ошибке billingType при сохранении заключения */
  billingTypeReportError: string | null
  /** Функция для изменения сообщения об ошибке billingType при сохранении */
  setBillingTypeReportError: Dispatch<SetStateAction<string | null>>
  /** Код ошибки */
  errorCode: string
  /** Функция для изменения кода ошибки */
  setErrorCode: Dispatch<SetStateAction<string>>
  /** Сообщение валидации заключения */
  validateMessages: ValidateMessages
  /** Флаг, указывающий видимость модального окна услуг */
  isServicesModalVisible: boolean
  /** Список выбранных платных услуг */
  selectedServices: BillableServicesType[]
  /** Выбранная сложность (числовое значение или null) */
  selectedComplexity: number | null
  /** Функция для изменения видимости модального окна услуг */
  setServicesModalVisible: Dispatch<SetStateAction<boolean>>
  /** Функция для изменения списка выбранных услуг */
  setSelectedServices: Dispatch<SetStateAction<BillableServicesType[]>>
  /** Функция для изменения выбранной сложности */
  setSelectComplexity: Dispatch<SetStateAction<number | null>>
  /** Флаг, указывающий, что заключение в данный момент подписывается */
  isSigning: boolean
  /** Функция для изменения флага подписания заключения */
  setIsSigning: Dispatch<SetStateAction<boolean>>
  /** Флаг, указывающий, что заключение в данный момент сохраняется как черновик */
  isDrafting: boolean
  /** Функция для изменения флага сохранения заключения в черновик */
  setIsDrafting: Dispatch<SetStateAction<boolean>>
}

const ReportCreationContext = createContext<ReportCreationContextProps | null>(null)

export const useReportCreationContext = () => {
  const context = useContext(ReportCreationContext)

  if (!context) {
    throw new Error()
  }

  return context
}

const ReportCreationContextProvider: FC = ({ children }) => {
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const [form] = Form.useForm<ReportFormData>()
  const [hasValidateErrors, setHasValidateErrors] = useState<boolean>(false)
  const initialReport = useTypedSelector((state) => state.reports.creationModalInitialReport) ?? {}

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isDZM) {
      dispatch(reportsSlice.actions.resetInitialReport({ icd10DetailedListMode: true }))
    }
  }, [isDZM])

  const [isActionModalVisible, setIsActionModalVisible] = useState<boolean>(false)
  const [isServicesModalVisible, setServicesModalVisible] = useState<boolean>(false)
  const [isConflictError, setIsConflictError] = useState<boolean>(false)
  const [saveReportError, setSaveReportError] = useState<string>('')
  const [selectedComplexity, setSelectComplexity] = useState<number | null>(null)

  const [selectedServices, setSelectedServices] = useState<BillableServicesType[]>([])
  const [billingTypeReportError, setBillingTypeReportError] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<string>('')
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [isDrafting, setIsDrafting] = useState<boolean>(false)

  /**
   * Пользовательские сообщения валидации для полей формы
   */
  const validateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  /**
   * Проверяет поля формы и обновляет состояние ошибок валидации
   * @param {ReportFormData} values - Объект со значениями формы
   * @param {string} values.complexity - Уровень сложности отчета
   * @param {string} values.icd10 - Код МКБ-10
   * @param {string} values.report - Текст отчета
   * @description
   * Проверяет заполнение обязательных полей:
   * - report (отчет)
   * - icd10 (код МКБ-10)
   * - complexity (сложность, только для Gemotest)
   *
   * При наличии пустых обязательных полей устанавливает флаг ошибки валидации
   */
  const validateFormFields = (values: ReportFormData) => {
    const { complexity, icd10, report } = values

    if (!report || !icd10 || ((isGemotest || isDZM) && !complexity)) {
      setHasValidateErrors(true)
    } else {
      setHasValidateErrors(false)
    }
  }

  /**
   * Обработчик изменения значений формы
   * @description
   * Проверяет заполнение обязательных полей:
   * - report (отчет)
   * - icd10 (код МКБ-10)
   * - complexity (сложность, только для Gemotest)
   *
   * Запускает валидацию формы при каждом изменении любого поля
   */
  const onValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    validateFormFields(values)
  }

  /**
   * Запуск начальной валидации формы
   * @description
   * Проверяет корректность заполнения полей при первом рендере компонента
   */
  useEffect(() => {
    validateFormFields(form.getFieldsValue())
  }, [])

  return (
    <ReportCreationContext.Provider
      value={{
        billingTypeReportError,
        errorCode,
        form,
        hasValidateErrors,
        initialReport,
        isActionModalVisible,
        isConflictError,
        isDrafting,
        isServicesModalVisible,
        isSigning,
        onValuesChange,
        saveReportError,
        selectedComplexity,
        selectedServices,
        setBillingTypeReportError,
        setErrorCode,
        setIsActionModalVisible,
        setIsConflictError,
        setIsDrafting,
        setIsSigning,
        setSaveReportError,
        setSelectComplexity,
        setSelectedServices,
        setServicesModalVisible,
        validateMessages,
      }}
    >
      {children}
    </ReportCreationContext.Provider>
  )
}

export default ReportCreationContextProvider

/** Тип данных для IUser без обязательного поля fullname для МЗ */
export type IUserReport = Omit<IUser, 'fullname'> & { fullname?: string }

/**
 * Тип данных Мед. заключения
 */
export type ReportFormData = {
  /** Признак блокировки для отмены подписания */
  locked?: boolean
  /** Признак подписи, для отправки в заключения в ЛИС */
  signed?: boolean
  /** Код заболевания по МКБ-10 */
  icd10?: DictionaryItem
  /** Детализированный список кодов МКБ-10 */
  icd10DetailedList?: Partial<ICD10Detailed>[]
  /** Морфологический код */
  morphologyCode?: DictionaryItem
  /** Патологоанатомическое заключение */
  report?: string | null
  /** Доп замечания и рекомендации */
  comment?: string | null
  /** Макроописание из данных по кейсу */
  caseMacroDescription?: string
  /** Категория сложности */
  complexity?: number
  /** Замена отчета */
  replaceReport?: boolean
  /** Список платных услуг */
  billableServices?: BillableServicesType[]
  /** Массив Enum (Требуется ИГХ и Требуется МГИ) */
  additionalResearchRequired?: string[]
  /** Врач-консультант  */
  consultedBy?: IUserReport | null
  /** Микроописание */
  microDescription?: string | null
  /** Макроописание */
  macroDescription?: string | null
  /** Структурированое макроописания для DZM */
  structuredMacroDescription?: StructuredDescription[] | null
  /** Структурированое микроописания для DZM */
  structuredMicroDescription?: StructuredDescription[] | null
  /** Результаты ИГХ исследований */
  ihcResult?: string | null
  /** Результаты доп. исследований */
  additionalResearchResult?: string | null
  /** Окраски маркеров */
  markerResults?: markerResult[]
}

export type markerResult = {
  /** Id маркера **/
  medicalReportMarkerResultId?: number
  /** Окраска слайд маркера **/
  stainId?: number
  /** Идентификатор ссылки на слайд маркера */
  caseSlideReferenceId: number
  /** Детали окраски маркера*/
  markerResult?: DictionaryItem
  /** Результат по маркеру */
  resultText?: string
}
