import { TooltipProps } from 'antd/es/tooltip'
import styled, { CSSProperties } from 'styled-components/macro'

import { ButtonElement } from './ButtonElement'
import { IconElement, IIconElementProps } from './IconElement'
import { TooltipElement } from './TooltipElement'

type Props = {
  title?: string
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  icon: IIconElementProps['name']
  placement?: TooltipProps['placement']
  style?: CSSProperties
  disabled?: boolean
  fill?: string
  id?: string
}

const SmallButton = styled(ButtonElement)`
  &.ant-btn-icon-only {
    width: 16px;
    height: 16px;
    background: none;
    color: var(--color-text-3);
  }
`

export const SmallIconButtonElement = ({
  disabled,
  fill,
  icon,
  id,
  onClick,
  placement,
  style,
  title,
  ...props
}: Props) => (
  <TooltipElement title={title} placement={placement}>
    <SmallButton
      id={id}
      icon={<IconElement name={icon} size="md" fill={fill} />}
      style={style}
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  </TooltipElement>
)
