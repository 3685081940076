import { IBillableService } from 'types/IDictionary'

/**
 * Фильтрация списка услуг по уровню сложности и типу оплаты.
 *
 * @param {Array} services - Массив объектов услуг для фильтрации.
 * @param {number} complexity - Требуемый уровень сложности услуги.
 * @param {string} billingType - Требуемый тип оплаты услуги.
 * @returns {Array} - Отфильтрованный массив услуг.
 */
export const getFilteredServices = (
  complexity: number,
  billingType?: string | null,
  services?: IBillableService[],
): IBillableService[] => {
  if (!billingType) return []
  return (
    services?.filter((service) => {
      // Проверяем, что уровень сложности совпадает
      const hasMatchingComplexity = service.complexities.includes(complexity)

      // Проверяем, что тип оплаты совпадает
      const hasMatchingBillingType = service.billingTypes.some((type) => type.name === billingType)

      return hasMatchingComplexity && hasMatchingBillingType
    }) || []
  )
}
