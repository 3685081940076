import { ISegmentedOption } from 'types/IReport'

/**
 * Массив опций для выбора категории сложности
 * @type {Array<ISegmentedOption<number>>}
 *
 * @description
 * Предоставляет набор опций для выбора категории сложности от 1 до 5.
 * Каждая опция содержит числовое значение и соответствующую текстовую метку.
 *
 * @property {string} label - Текстовая метка для отображения
 * @property {number} value - Числовое значение категории сложности
 */
export const COMPLEXITY_OPTIONS: ISegmentedOption<number>[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]

/**
 * Массив опций для выбора стадии патологии
 * @type {Array<ISegmentedOption<boolean>>}
 *
 * @description
 * Предоставляет опции для указания стадии патологии:
 * - pT: Патологическая стадия без предшествующего лечения
 * - ypT: Патологическая стадия после неоадъювантной терапии
 *
 * @property {string} label - Текстовая метка стадии (pT или ypT)
 * @property {boolean} value - Булево значение, где false = pT, true = ypT
 */
export const PATHOLOGY_STATE_OPTIONS: ISegmentedOption<boolean>[] = [
  { label: 'pT', value: false },
  { label: 'ypT', value: true },
]
