import { FormInstance } from 'antd'
import { ReportFormData } from 'features/reports/ui/report-creation/ReportCreationContext'
import { useTranslation } from 'react-i18next'
import IReport from 'types/IReport'
import { DescriptionValidator, DictionaryValidator, StringValidator } from 'types/IValidators'

export const useValidations = (initialReport: Partial<IReport>, form: FormInstance<ReportFormData>) => {
  // in initial call fields from form is not defined, so for validation we merge it with initialReport
  const getMergedFields = () => {
    const fields = form.getFieldsValue()
    return {
      ...initialReport,
      ...fields,
    }
  }
  const isValidRequiredForAll = () => {
    const { icd10, microDescription, morphologyCode, report } = getMergedFields()
    if (!form.isFieldsTouched()) {
      return Object.values(initialReport).length === 0
    }
    return (
      morphologyCode !== null &&
      morphologyCode !== undefined &&
      icd10 !== undefined &&
      report !== '' &&
      report !== undefined &&
      microDescription !== '' &&
      microDescription !== null
    )
  }
  const isValidCommonRequired = () => {
    const { icd10, report } = getMergedFields()
    return icd10 !== undefined && !!report
  }
  const isValidGemotestRequired = () => {
    const { caseMacroDescription } = getMergedFields()
    return isValidRequiredForAll() && caseMacroDescription
  }

  /** Проверяем обязательность полей для сохранения черновика */
  const isGemotestDraftRequired = () => {
    const { report } = getMergedFields()

    return !!report
  }

  /** Проверяем обязательность полей для подписания в ЛИС */
  const isGemotestSignRequired = () => {
    const { complexity, icd10 } = getMergedFields()

    return isGemotestDraftRequired() && complexity && icd10
  }

  return { isGemotestDraftRequired, isGemotestSignRequired, isValidCommonRequired, isValidGemotestRequired }
}

type UseFormValidators = (isSigning: boolean) => {
  validateMessages: { required: string }
  validators: {
    stringValidator: StringValidator
    descriptionValidator: DescriptionValidator
    selectValidator: DictionaryValidator
  }
}
export const useFormValidators: UseFormValidators = (isSigning: boolean) => {
  const { t } = useTranslation()

  const validateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  const resolve = () => Promise.resolve()
  const reject = () => Promise.reject(new Error(validateMessages.required))

  return {
    validateMessages,
    validators: {
      descriptionValidator: (_, value) => {
        const isValid = value?.every((item) => item.text?.trim() !== '')
        return !isSigning || isValid ? resolve() : reject()
      },
      selectValidator: (_, value) => (!isSigning || value?.id ? resolve() : reject()),
      stringValidator: (_, value) => {
        const isValid = value?.trim() !== ''
        return !isSigning || isValid ? resolve() : reject()
      },
    },
  }
}
