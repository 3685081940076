import { SpinElement } from 'shared/ui/kit'
import styled from 'styled-components'

export const LoadingContainer = styled.div`
  color: var(--color-text-1);
  width: 100%;
  display: flex;
  gap: 8px;
`

export const StyledSpineElement = styled(SpinElement)`
  display: flex;
  margin: auto;
`
