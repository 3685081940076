import ICase, { ICaseRelation } from 'types/ICase'
import ISlide from 'types/ISlide'

import mrt from './images/petrova-mrt.png'

const UZI_CASE_PETROVA: ICase = {
  billingType: {
    code: '1',
    id: 3,
    name: 'ОМС',
    shortName: '1',
  },
  caseId: 999999904,
  casePathologicalInfo: {
    casePathologicalInfoId: 1,
    diagnosticProcedureType: { id: 3, name: 'UZI', shortName: '6' },
    sites: [
      {
        container: null,
        site: {
          id: 999,
          name: 'Тело матки',
          shortName: '999',
        },
      },
    ],
  },
  caseRadiologicalInfo: {
    description: '',
    site: '',
  },
  caseType: 'PATHOLOGICAL',
  commentInfo: {
    lastReadCommentId: 0,
    unreadCount: 0,
  },
  createdAt: '2020-12-29T13:21:39.791',
  deleted: false,
  deletedAt: null,
  description:
    'Лейомиосаркома тела матки low grade (p)T1bN0M0. 26.08.2016 - хирургическое лечение.3 цикла ПХТ по схеме Доксорубицин+Карбоплатин. Рецидив от 12.2020 (образование в малом тазу). ',
  dueDate: null,
  expirationState: null,
  favoriteLists: [],
  materialTakeoutDate: '2020-12-29T13:00:00',
  modalities: [],
  name: 'МРТ00567489',
  ohivPath: '/ohiv/viewer/2.16.840.1.114488.0.4.123489834087.1330071425.0',
  patient: {
    birthDate: '1948-12-14',
    fullname: 'Петрова Анна Юрьевна',
    sex: 'FEMALE',
  },
  relation: ICaseRelation.OWNER,
  siteSpecificMetadata: {
    gleasonGroupCalculated: 5,
    type: 'PROSTATE_CANCER_METADATA',
  },
  slideDefect: null,
  slideStats: {
    referencesCount: 0,
    slidesCount: 0,
  },
  source: 'PLATFORM',
  status: 'OPEN',
  updatedAt: '2020-12-29T21:42:39.736',
  user: {
    email: 'test+spectre@onecell.ai',
    fullname: 'Кирилл Кошелев',
    userId: 51,
  },
}

export const UZI_CASE_PETROVA_SLIDES: ISlide[] = [
  {
    caseId: 999999904,
    deleted: false,
    deletedAt: null,
    groupType: 'MICRO',
    iiif2Url:
      'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
    searchModels: [],
    slideDefect: null,
    slideId: 9999999041,
    slideMetadata: {
      caseSpecificMetadata: {
        gleasonGroup: 5,
        gleasonPatternPrimary: 5,
        gleasonPatternSecondary: 4,
        pattern2Area: {
          GLEASON4: 155.0,
          GLEASON5: 682.0,
          OTHER: 1.988905e7,
        },
        result: {
          GLEASON4: {
            classId: 3,
            relativeValue: 0.1852,
          },
          GLEASON5: {
            classId: 4,
            relativeValue: 0.8148,
          },
        },
        type: 'PROSTATE_CANCER_METADATA',
      },
      commonMetadata: {
        caption: 'OHIV-1.3.6.1.4.1.14519-1',
        mppX: 0.2498,
        objectivePower: 40,
        segmentationStatus: 'AVAILABLE',
      },
    },
    state: 'AVAILABLE',
    thumbnails: {
      large: mrt,
      medium: mrt,
      small: mrt,
    },
    viewed: true,
  },
  // {
  //   caseId: 999999904,
  //   slideId: 99999992,
  //   state: 'AVAILABLE',
  //   slideMetadata: {
  //     commonMetadata: {
  //       mppX: 0.2498,
  //       objectivePower: 40,
  //       caption: 'OHIV-1.3.6.1.4.1.14519-2',
  //       isSegmentationAvailable: 'AVAILABLE',
  //       isSearchAvailable: 'AVAILABLE',
  //     },
  //     caseSpecificMetadata: {
  //       result: {
  //         GLEASON4: {
  //           relativeValue: 0.1852,
  //           classId: 3,
  //         },
  //         GLEASON5: {
  //           relativeValue: 0.8148,
  //           classId: 4,
  //         },
  //       },
  //       gleasonPatternPrimary: 5,
  //       gleasonPatternSecondary: 4,
  //       gleasonGroup: 5,
  //       pattern2Area: {
  //         OTHER: 1.988905e7,
  //         GLEASON4: 155.0,
  //         GLEASON5: 682.0,
  //       },
  //       type: 'PROSTATE_CANCER_METADATA',
  //     },
  //   },
  //   thumbnails: {
  //     large: ohiv_2,
  //     small: ohiv_2,
  //     medium: ohiv_2,
  //   },
  //   deletedAt: null,
  //   iiif2Url:
  //     'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
  //   deleted: false,
  // },
  // {
  //   caseId: 999999904,
  //   slideId: 99999993,
  //   state: 'AVAILABLE',
  //   slideMetadata: {
  //     commonMetadata: {
  //       mppX: 0.2498,
  //       objectivePower: 40,
  //       caption: 'OHIV-1.3.6.1.4.1.14519-3',
  //       isSegmentationAvailable: 'AVAILABLE',
  //       isSearchAvailable: 'AVAILABLE',
  //     },
  //     caseSpecificMetadata: {
  //       result: {
  //         GLEASON4: {
  //           relativeValue: 0.1852,
  //           classId: 3,
  //         },
  //         GLEASON5: {
  //           relativeValue: 0.8148,
  //           classId: 4,
  //         },
  //       },
  //       gleasonPatternPrimary: 5,
  //       gleasonPatternSecondary: 4,
  //       gleasonGroup: 5,
  //       pattern2Area: {
  //         OTHER: 1.988905e7,
  //         GLEASON4: 155.0,
  //         GLEASON5: 682.0,
  //       },
  //       type: 'PROSTATE_CANCER_METADATA',
  //     },
  //   },
  //   thumbnails: {
  //     large: ohiv_3,
  //     small: ohiv_3,
  //     medium: ohiv_3,
  //   },
  //   deletedAt: null,
  //   iiif2Url:
  //     'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
  //   deleted: false,
  // },
  // {
  //   caseId: 999999904,
  //   slideId: 99999994,
  //   state: 'AVAILABLE',
  //   slideMetadata: {
  //     commonMetadata: {
  //       mppX: 0.2498,
  //       objectivePower: 40,
  //       caption: 'OHIV-1.3.6.1.4.1.14519-4',
  //       isSegmentationAvailable: 'AVAILABLE',
  //       isSearchAvailable: 'AVAILABLE',
  //     },
  //     caseSpecificMetadata: {
  //       result: {
  //         GLEASON4: {
  //           relativeValue: 0.1852,
  //           classId: 3,
  //         },
  //         GLEASON5: {
  //           relativeValue: 0.8148,
  //           classId: 4,
  //         },
  //       },
  //       gleasonPatternPrimary: 5,
  //       gleasonPatternSecondary: 4,
  //       gleasonGroup: 5,
  //       pattern2Area: {
  //         OTHER: 1.988905e7,
  //         GLEASON4: 155.0,
  //         GLEASON5: 682.0,
  //       },
  //       type: 'PROSTATE_CANCER_METADATA',
  //     },
  //   },
  //   thumbnails: {
  //     large: ohiv_4,
  //     small: ohiv_4,
  //     medium: ohiv_4,
  //   },
  //   deletedAt: null,
  //   iiif2Url:
  //     'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
  //   deleted: false,
  // },
]

export default UZI_CASE_PETROVA
