import { useThemeContext } from 'app/styled/ThemeProvider'
import { FieldSet } from 'entities/lis-integration/types'
import { useSegmentedHandlers } from 'features/reports/hooks/useSegmentedHandlers'
import { useFormValidators } from 'features/reports/hooks/useValidations'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { SegmentElement } from 'shared/ui/kit'
import { StyledCheckboxElement } from 'viewer/map/layers/annotations/ui/AnnotationDescriptionAndAreaControl'

/** Тип для значений компонента выбора категории сложности */
type IComplexityOption = {
  /** Лейбл значения категории сложности */
  label: string
  /** Значение категории сложности */
  value: number
}

const COMPLEXITY_OPTIONS: IComplexityOption[] = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
]

type Props = {
  isSigning: boolean
  fieldSet?: FieldSet
  additionalResearchRequired?: string[] | null
}

const BlockComplexity = ({ additionalResearchRequired, fieldSet, isSigning }: Props) => {
  const { t } = useTranslation()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const theme = useThemeContext()
  const { form, setSelectComplexity } = useReportCreationContext()
  const { onSegmentedChange, onSegmentedClick } = useSegmentedHandlers(form, setSelectComplexity)
  const {
    validators: { stringValidator },
  } = useFormValidators(isSigning)

  /** Обработчкик для смены значения требования ИГХ и МГИ */
  const handleCheckboxChange = (checkedValue: string, isChecked: boolean) => {
    const currentValues = new Set(form.getFieldValue('additionalResearchRequired') || [])
    isChecked ? currentValues.add(checkedValue) : currentValues.delete(checkedValue)
    form.setFieldValue('additionalResearchRequired', Array.from(currentValues))
  }

  return (
    <MedicalReportFormItem
      label={t('Категория сложности')}
      name="complexity"
      style={{ maxWidth: '100%' }}
      rules={[{ required: isGemotest || isDZM, validator: isGemotest || isDZM ? stringValidator : () => {} }]}
    >
      <SegmentElement
        onClick={onSegmentedClick('complexity')}
        options={COMPLEXITY_OPTIONS}
        style={{ maxWidth: 120 }}
        colorTheme={theme.theme}
        onChange={onSegmentedChange('complexity')}
        size="small"
      />
      {/* Требуется ИГХ / Требуется МГИ */}
      {isDZM && (
        <MedicalReportFormItem name="additionalResearchRequired">
          {fieldSet !== 'IHC' && (
            <StyledCheckboxElement
              defaultChecked={additionalResearchRequired?.includes('IHC')}
              onChange={(e) => handleCheckboxChange('IHC', e.target.checked)}
            >
              {t('Требуется ИГХ')}
            </StyledCheckboxElement>
          )}
          <StyledCheckboxElement
            defaultChecked={additionalResearchRequired?.includes('MGT')}
            onChange={(e) => handleCheckboxChange('MGT', e.target.checked)}
          >
            {t('Требуется МГИ')}
          </StyledCheckboxElement>
        </MedicalReportFormItem>
      )}
    </MedicalReportFormItem>
  )
}

export default BlockComplexity
