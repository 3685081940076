import { EDictionaryNS } from 'features/dictionaries/api/service'
import React, { FC } from 'react'
import DictSelect from 'shared/ui/dict-select/DictSelect'

interface Props {
  dictType: EDictionaryNS
}

const TNMSelectContainer: FC<Props> = (props) => (
  <DictSelect
    selectProps={{ className: 'tnm-select', dropdownMatchSelectWidth: 126, placeholder: undefined, showArrow: false }}
    {...props}
  />
)

export default TNMSelectContainer
