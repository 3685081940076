import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import React, { memo } from 'react'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { areEqual, FixedSizeList as List } from 'react-window'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IBillableService } from 'types/IDictionary'

type ServiceItemProps = {
  service: IBillableService
}

const ServicesOptions = ({ dictServices }: { dictServices: IBillableService[] }) => {
  const initialScrollOffset = 0

  const Row = memo(
    ({ index, style }: { index: number; style: React.CSSProperties }) => (
      <ServiceItem style={style} service={dictServices[index]} />
    ),
    areEqual,
  )

  return (
    <OptionsWrapper>
      <AutoSizer defaultHeight={752} defaultWidth={728}>
        {({ height, width }: Size) => (
          <List
            height={height}
            itemCount={dictServices.length}
            itemSize={32}
            width={width}
            initialScrollOffset={initialScrollOffset}
            overscanCount={5}
            itemData={dictServices}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </OptionsWrapper>
  )
}

export default ServicesOptions

const ServiceItem: React.FC<ServiceItemProps & { style: React.CSSProperties }> = memo(({ service, style }) => {
  const { form, selectedServices, setSelectedServices, setServicesModalVisible } = useReportCreationContext()
  const { billingTypes, code, complexities, id, name, price, shortName } = service

  const isSelected = selectedServices.some((service) => service.billableService.id === id)

  const handleSelect = () => {
    setSelectedServices((prev) => [
      ...prev,
      {
        billableService: { billingTypes, code, complexities, id, name, price, shortName },
        price,
        total: price,
      },
    ])
    form.setFieldValue('billableServices', [
      ...selectedServices,
      { billableService: { id, name, shortName }, price, quantity: 1 },
    ])
    setServicesModalVisible(false)
  }

  return (
    <Container isSelected={isSelected} onClick={handleSelect} style={style}>
      <InfoContainer>
        <TextElement>{shortName}</TextElement>
        <TextElement style={{ width: 525 }}>{name}</TextElement>
      </InfoContainer>
      <Price>{price} ₽</Price>
    </Container>
  )
})

const Container = styled.div<{ isSelected: boolean }>`
  padding: 8px;
  display: flex;
  border-radius: 5px;
  height: 32px;
  opacity: ${({ isSelected }) => (isSelected ? 0.5 : 1)};
  pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'all')};
  cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
  justify-content: space-between;
  :hover {
    background: var(--color-bg-3);
  }
`

const InfoContainer = styled.div`
  display: flex;
  gap: 4px;
`

const Price = styled(TextElement)`
  width: 90px;
  text-align: end;
`

const OptionsWrapper = styled.div`
  height: 752px;
  width: 728px;
`
