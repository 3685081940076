import { Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import { CaseBiomaterial } from 'features/cases/api/service'
import { ONE_MINUTE_IN_MS, SEC_TO_MINUTES } from 'features/cases-management/ui/cases-table/CasesTable'
import { reportsSlice, selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisLockSignedMedicalReportAfterSec } from 'features/workspace/model/workspacesSlice'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ButtonElement, TextElement } from 'shared/ui/kit'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components'
import IPatient from 'types/IPatient'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

import { onSaveReportError, useReportEditMutation, useReportQuery, useReportsDataQuery } from './api/query'
import ReportCreationForm from './ui/report-creation/ReportCreationForm'
import ReportInfo from './ui/ReportInfo'

/** Opacity для заблокированной кнопки */
const DISABLED_BUTTON_OPACITY = 0.4

const StyledButtonElement = styled(ButtonElement)<{ isDZM?: boolean; caseBiomaterial?: CaseBiomaterial[] }>`
  width: 100%;
  opacity: ${({ caseBiomaterial, isDZM }) => (isDZM && !caseBiomaterial?.length ? DISABLED_BUTTON_OPACITY : undefined)};
`

type Props = {
  caseId: number
  patient: IPatient
  source: ISource
  reportCreationModalRef: RefObject<ModalHandle>
  /** Возможность копировать заключение */
  copyDisable: boolean
  /** Флаг определяющий relation */
  isRestricted: boolean
  skipCaseInfo?: boolean
}

const ReportInfoContainer = ({
  caseId,
  copyDisable,
  isRestricted,
  patient,
  reportCreationModalRef,
  skipCaseInfo,
  source,
}: Props) => {
  const { t } = useTranslation()
  const { data: caseReports } = useReportsDataQuery(caseId, source)
  const selectedReportIndex = useTypedSelector(selectedReportByCaseSelector(caseId))
  const selectedReportId =
    caseReports?.[selectedReportIndex]?.medicalReportId || Number(caseReports?.[0]?.medicalReportId)
  const { viewerType } = useViewerPageProvided()
  const { data: report, refetch: refetchReport } = useReportQuery(caseId, selectedReportId, source)
  const { locked, medicalReportId, signed, signedAt } = report || {}
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  // период в секундах, в рамках которого можно отменить подписание
  const signCancelPeriod = useLisLockSignedMedicalReportAfterSec() || 0
  // текущее время
  const currentDate = new Date()
  // количество прошедших минут после подписания
  const passedMinutes = signedAt
    ? Math.ceil((currentDate.getTime() - new Date(signedAt || '').getTime()) / ONE_MINUTE_IN_MS)
    : 0
  const isReadOnly = (viewerType === 'ATLAS' || source === 'ATLAS') && !copyDisable
  const { isLoading, mutate: editMutate } = useReportEditMutation()
  // количество оставшися минут для отмены подписания
  const leftMinutes = Number(signCancelPeriod) / SEC_TO_MINUTES - passedMinutes
  const [signCancelMinutes, setSignCancelMinutes] = useState<number>(leftMinutes > 0 ? leftMinutes : 0)
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const wsId = Number(useCurrentWorkspaceId())
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: wsId,
  })
  // Метод для отображение окна с данными отчета
  const showReport = (report?: IReport) => {
    if (report) {
      dispatch(reportsSlice.actions.setInitialReport(report))
      reportCreationModalRef.current?.open()
    }
  }

  // Обработчик кнопки редактирования отчета
  const openEditModal = async () => {
    if (isGemotest || isDZM) {
      refetchReport().then((res) => {
        const reportData = res?.data
        !reportData?.signed ? showReport(reportData) : queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }])
      })
    } else {
      showReport(report)
    }
  }
  const openNewModal = () => reportCreationModalRef.current?.open()
  /** запрос на отмену подписания */
  const onCancelSign = async () => {
    editMutate(
      {
        caseId,
        // принудительно отменяем подписание
        report: { ...report, signed: false },
        reportId: Number(medicalReportId),
      },
      { onError: (e) => onSaveReportError(e, caseId) },
    )
  }

  // запускаем таймер для обновления оставшегося времени отмены подписания в ЛИС
  useEffect(() => {
    const timerId = !locked
      ? setInterval(() => {
          signCancelMinutes && setSignCancelMinutes(signCancelMinutes - 1)
        }, ONE_MINUTE_IN_MS)
      : null

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [locked, signCancelMinutes])

  // обновляем время отмены подписания, если поменлся его признак
  useEffect(() => {
    if (signed && signedAt && leftMinutes > 0 && (isGemotest || isDZM)) {
      setSignCancelMinutes(leftMinutes)
    }
  }, [signed])

  // вручную обновляем locked по истечению времени для отмены подписания
  useEffect(() => {
    if (signed && !signCancelMinutes && (isDZM || isGemotest)) {
      queryClient.setQueryData([QUERY_TYPE.REPORT, selectedReportId], {
        ...report,
        locked: true,
      })
    }
  }, [signCancelMinutes])

  return (
    <Space size={8} direction="vertical" style={{ width: '100%' }}>
      <ReportInfo
        openModal={openNewModal}
        patient={patient}
        reportData={report}
        readOnly={isReadOnly}
        caseId={caseId}
        source={source}
        skipCaseInfo={skipCaseInfo}
      />
      {(isGemotest || isDZM) && report && !signed && !isRestricted && (
        <StyledButtonElement
          disabled={isDZM && !caseBiomaterial?.length}
          onClick={openEditModal}
          isDZM={isDZM}
          caseBiomaterial={caseBiomaterial}
        >
          {t('Редактировать')}
        </StyledButtonElement>
      )}
      {(isGemotest || isDZM) && !!signed && !locked && !!signCancelMinutes && !isRestricted && (
        <div>
          <StyledButtonElement disabled={isRestricted} onClick={onCancelSign} loading={isLoading}>
            {t('Отменить подписание')}
          </StyledButtonElement>
          <div style={{ marginTop: '8px', textAlign: 'center' }}>
            <TextElement>{`${t('Отмена подписания будет еще доступна в течение')} ${signCancelMinutes} ${t('минут')}${
              signCancelMinutes === 1 ? t('ы') : ''
            }`}</TextElement>
          </div>
        </div>
      )}
      <ReportCreationForm modalRef={reportCreationModalRef} caseId={caseId} source={source} />
    </Space>
  )
}

export default ReportInfoContainer
