import { EDictionaryNS } from 'features/dictionaries/api/service'
import React, { FC, PropsWithChildren } from 'react'
import DictSelect from 'shared/ui/dict-select/DictSelect'

interface Props {
  /** Окраска для выбираемого справочника **/
  stainId?: number
}

const MarkerResultSelectContainer: FC<PropsWithChildren<Props>> = (props) => (
  <DictSelect dictType={EDictionaryNS.MARKER_RESULT} {...props} />
)

export default MarkerResultSelectContainer
