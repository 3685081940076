import { FormInstance, RadioChangeEvent } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'

export const useSegmentedHandlers = (
  form: FormInstance,
  setSelectComplexity: Dispatch<SetStateAction<number | null>>,
) => ({
  onSegmentedChange: (name: string) => (event: RadioChangeEvent) => {
    const value = event.target.value
    form.setFieldValue(name, value)
    setSelectComplexity(value)
  },
  onSegmentedClick: (name: string) => (e: React.BaseSyntheticEvent) => {
    e.preventDefault()

    if (
      e?.target?.className?.includes('ant-radio-button-checked') ||
      e?.target?.parentElement?.className?.includes('ant-radio-button-wrapper-checked')
    ) {
      form.setFieldValue(name, undefined)
      setSelectComplexity(null)
    }
  },
})
